import { createAction, combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import localForage from 'localforage';

import journeysReducer from '../features/routes/journeysSlice';
import routesReducer from '../features/routes/routesSlice';
import navigationsReducer from '../features/routes/navigationsSlice';
import stopsReducer from '../features/routes/stopsSlice';
import routePointsReducer from '../features/routePoints/routePointsSlice';
import transportsReducer from '../features/transports/transportsSlice';
import trackersReducer from '../features/transports/trackersSlice';
import vehiclesReducer from '../features/transports/vehiclesSlice';
import shipmentsReducer from '../features/shipments/shipmentsSlice';
import depotsReducer from '../features/depots/depotsSlice';
import layersReducer from '../features/layers/layersSlice';
import restrictionsReducer from '../features/restrictions/restrictionsSlice';
import usersReducer from '../features/users/usersSlice';

import cities from '../config/cities';

export const resetState = createAction('global/RESET_STATE');


const currentVersion = 4;

const migrate = (state, version) => {
  if (state && state._persist && state._persist.version !== version) {
    console.log("Migrate -> clean state");
    return Promise.resolve(undefined);
  }
  return Promise.resolve(state);
}

const authPersistReducer = persistReducer(
  {
    key: "auth",
    storage: localForage
  },
  usersReducer
);

export const authCombinedReducer = combineReducers({
  users: authPersistReducer
})

export const persistReducers = Object.fromEntries(cities.map(city => {
  // Depots
  const depotsPersistReducer = persistReducer(
    {
      key: `${city.title}:depots`,
      storage: localForage,
      version: currentVersion,
      migrate: migrate
    },
    depotsReducer
  );

  // Layers
  const layersPersistReducer = persistReducer(
    {
      key: `${city.title}:layers`,
      storage: localForage,
      version: currentVersion,
      migrate: migrate
    },
    layersReducer
  );

  // Restrictions
  const restrictionsPersistReducer = persistReducer(
    {
      key: `${city.title}:restrictions`,
      storage: localForage,
      version: currentVersion,
      migrate: migrate
    },
    restrictionsReducer
  );

  // Journeys
  const journeysPersistReducer = persistReducer(
    {
      key: `${city.title}:journeys`,
      storage: localForage,
      version: currentVersion,
      migrate: migrate
    },
    journeysReducer
  );

  // Routes
  const routesPersistReducer = persistReducer(
    {
      key: `${city.title}:routes`,
      storage: localForage,
      blacklist: ['status', 'isUpdating', 'error', 'info'],
      version: currentVersion,
      migrate: migrate
    },
    routesReducer,
  );

  // Navigations
  const navigationsPersistReducer = persistReducer(
    {
      key: `${city.title}:navigations`,
      storage: localForage,
      version: currentVersion,
      migrate: migrate
    },
    navigationsReducer
  );

  // Stops
  const stopsPersistReducer = persistReducer(
    {
      key: `${city.title}:stops`,
      storage: localForage,
      version: currentVersion,
      migrate: migrate
    },
    stopsReducer
  );

  // Shipments
  const shipmentsPersistReducer = persistReducer(
    {
      key: `${city.title}:shipments`,
      storage: localForage,
      version: currentVersion,
      migrate: migrate
    },
    shipmentsReducer
  );

  // RoutesPointss
  const routePointsPersistReducer = persistReducer(
    {
      key: `${city.title}:routePoints`,
      storage: localForage,
      version: currentVersion,
      migrate: migrate
    },
    routePointsReducer
  );

  // Trackers
  const trackersPersistReducer = persistReducer(
    {
      key: `${city.title}:trackers`,
      storage: localForage,
      version: currentVersion,
      migrate: migrate
    },
    trackersReducer
  );

  // Transports
  const transportsPersistReducer = persistReducer(
    {
      key: `${city.title}:transports`,
      storage: localForage,
      version: currentVersion,
      migrate: migrate
    },
    transportsReducer
  );

  // Vehicles
  const vehiclesPersistReducer = persistReducer(
    {
      key: `${city.title}:vehicles`,
      storage: localForage,
      version: currentVersion,
      migrate: migrate
    },
    vehiclesReducer
  );

  // All reducers
  const cityAllReducers = combineReducers({
    depots: depotsPersistReducer,
    layers: layersPersistReducer,
    restrictions: restrictionsPersistReducer,
    journeys: journeysPersistReducer,
    routes: routesPersistReducer,
    navigations: navigationsPersistReducer,
    stops: stopsPersistReducer,
    shipments: shipmentsPersistReducer,
    routePoints: routePointsPersistReducer,
    trackers: trackersPersistReducer,
    transports: transportsPersistReducer,
    users: authPersistReducer,
    vehicles: vehiclesPersistReducer
  });

  // Root reducer
  const cityRootReducer = (state, action) => {
    if (action.type === 'global/RESET_STATE') {
      console.log("Action RESET_STATE");
      const { users } = state;
      state = { users };
    }

    return cityAllReducers(state, action);
  }

  return [city.title, cityRootReducer];
}));
