import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom';

import cities from './config/cities';

import City from './City';
import NoCity from './NoCity';

export const App = () => {
  console.log("APP");

  return (
    <BrowserRouter>
      <Routes>
        {cities.map(city => (
          <Route
            key={city.name}
            path={`/${city.name}/*`}
            element={ <City city={city} /> }
          /> 
        ))}
        <Route path="/login" element={ <NoCity /> } />
        <Route path="*" element={ <Navigate to="/login" replace={true} /> } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
