import { connect, useSelector } from 'react-redux';

import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import JoinFullIcon from '@mui/icons-material/JoinFull';
import BlockIcon from '@mui/icons-material/Block';
import LocalParkingIcon from '@mui/icons-material/LocalParking';

import {
  setShowDepots,
  selectShowDepots
} from '../../../features/depots/depotsSlice';
import {
  setShowULL,
  selectShowULL,
  setShowLoadingAreas,
  selectShowLoadingAreas
} from '../../../features/layers/layersSlice';
import {
  setShowRestrictions,
  selectShowRestrictions
} from '../../../features/restrictions/restrictionsSlice';

const ListExtraLayers = ({ dispatch }) => {
  const showDepots = useSelector(selectShowDepots);
  const showULL = useSelector(selectShowULL);
  const showRestrictions = useSelector(selectShowRestrictions);
  const showLoadingAreas = useSelector(selectShowLoadingAreas);

  return (
    <>
      <ListItem sx={{ p: 0 }}>
        <ListItemButton
          onClick={() => dispatch(setShowDepots(!showDepots))}
        >
        <ListItemIcon>
         <WarehouseIcon />
        </ListItemIcon>
        <ListItemText id="switch-list-label-depots" primary="Depots" />
        <Checkbox
          edge="end"
          checked={showDepots}
          inputProps={{ 'aria-labelledby': 'switch-list-label-depots' }}
        />
      </ListItemButton>
      </ListItem>
      <ListItem sx={{ p: 0 }}>
        <ListItemButton
          onClick={() => dispatch(setShowULL(!showULL))}
        >
        <ListItemIcon>
         <JoinFullIcon />
        </ListItemIcon>
        <ListItemText
          id="switch-list-label-ull"
          primary="Urban Living Lab"
        />
        <Checkbox
          edge="end"
          checked={showULL}
          inputProps={{ 'aria-labelledby': 'switch-list-label-ull' }}
        />
        </ListItemButton>
      </ListItem>
      <ListItem sx={{ p: 0 }}>
        <ListItemButton
          onClick={() => dispatch(setShowRestrictions(!showRestrictions))}
        >
        <ListItemIcon>
         <BlockIcon />
        </ListItemIcon>
        <ListItemText
          id="switch-list-label-restrictions"
          primary="Restrictions"
        />
        <Checkbox
          edge="end"
          checked={showRestrictions}
          inputProps={{ 'aria-labelledby': 'switch-list-label-restrictions' }}
        />
        </ListItemButton>
      </ListItem>
      <ListItem sx={{ p: 0 }}>
        <ListItemButton
          onClick={() => dispatch(setShowLoadingAreas(!showLoadingAreas))}
        >
        <ListItemIcon>
         <LocalParkingIcon />
        </ListItemIcon>
        <ListItemText
          id="switch-list-label-parkings"
          primary="Loading Areas"
        />
        <Checkbox
          edge="end"
          checked={showLoadingAreas}
          inputProps={{ 'aria-labelledby': 'switch-list-label-parkings' }}
        />
        </ListItemButton>
      </ListItem>
    </>
  );
}

export default connect()(ListExtraLayers);
