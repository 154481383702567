import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit'
import { updateRouteLastLocation } from '../routes/navigationsSlice';
import { addStopToRoute } from '../routes/stopsSlice';
import {
  addRoutePoint,
  addDeliveryAction,
  relateRoutePoints,
  updateRoutePointPosition
} from '../routePoints/routePointsSlice';
import { updateTransportLocation } from './transportsSlice';
import { SenatorAPI } from '../../services/SenatorAPI';
import * as utils from '../routes/utils';

const trackersAdapter = createEntityAdapter();
const initialState = trackersAdapter.getInitialState();

export const trackerLocation = createAsyncThunk(
  'trackers/trackerLocation',
  async ({ trackerId, position }, thunkAPI) => {
    try {
    console.log("trackerLocation (1)", trackerId, position); // TODO DEBUG
    const state = thunkAPI.getState();
    let tracker = state.trackers.entities[trackerId];
    if (tracker) {
      console.log("trackerLocation (2) tracker exists", tracker); // TODO DEBUG
      // Update route's location
      const route = state.routes.entities[tracker.routeId];
      console.log("trackerLocation (3) Route from tracker", route);
      thunkAPI.dispatch(updateRouteLastLocation({
        routeNavId: `executed-${route.id}`,
        position: position
      }));
      thunkAPI.dispatch(updateTransportLocation({
          transportId: route.transportId,
          position: position
      }));
    }
    console.log("trackerLocation (4) finished"); // TODO DEBUG
    } catch (err) {
      console.error("trackerLocation", err);
    }
  }
);

export const shipmentStatus = createAsyncThunk(
  'trackers/shipmentStatus',
  async (
    { trackerId, shipmentCode, timestamp, position, actionStatus, substatus },
    thunkAPI
  ) => {
    try {
    console.log("shipmentStatus (1)", trackerId, shipmentCode, timestamp); // TODO DEBUG
    const state = thunkAPI.getState();
    const date= new Date(timestamp*1000);
    console.log("shipmentStatus (2)", date, date.toISOString().split('T')[0]); // TODO DEBUG
    let tracker = state.trackers.entities[trackerId];
    let routeId;
    if (tracker == null) {
      await new Promise((resolve) => { setTimeout(resolve, 1000); });
      const route = await SenatorAPI.findRouteByTracker(
        trackerId, date.toISOString().split('T')[0]
      );
      routeId = route.id;      
      console.log("shipmentStatus (3) routeId", routeId);
      thunkAPI.dispatch(createTracker({
        id: trackerId,
        // trackerId: route.tracker.id, // TODO review
        routeId: routeId
      }));
    } else {
      routeId = tracker.routeId;
    }
    if (routeId) {
      await new Promise((resolve) => { setTimeout(resolve, 500); });
      const execStops = await SenatorAPI.fetchStops(routeId, 'executed');
      const route = state.routes.entities[routeId];
      processExecutedStops(state, thunkAPI, routeId, route.name, execStops);
    }
    } catch (err) {
      console.error("shipmentStatus", err);
    }
  }
);

const processExecutedStops = (state, thunkAPI, routeId, routeName, stops) => {
  let thereIsNewStop = false;
  stops.routePoints.forEach(stop => {
    const newPosition = [stop.lat, stop.lng];
    const routePointId = `executed-${routeId}-${stop.routeOrder}`;
    const existingStop = state.routePoints.entities[routePointId];
    if (existingStop) {
      // Update position if required
      if (existingStop.position[0] !== newPosition[0] ||
        existingStop.position[1] !== newPosition[1]) {
        thunkAPI.dispatch(updateRoutePointPosition({
          routePointId: existingStop.id,
          position: newPosition          
}));
      }
      if (existingStop.actions.length !== stop.deliveryActions.length) {
        const receivedActions = stop.deliveryActions.map(action => {
          return utils.processDeliveryAction(action);
        });
        const existingDAs = existingStop.actions.map(action => {
          return action.shipment;
        });
        const newActions = receivedActions.filter(action => {
          return existingDAs.indexOf(action.shipment) === -1;
        });
        newActions.forEach(action => {
          thunkAPI.dispatch(addDeliveryAction({
            routePointId: existingStop.id,
            deliveryAction: action
          }));
        });
      }
    } else {
      const routePoint = utils.processRoutePoint(stop, 'executed', routeName);
      thunkAPI.dispatch(addRoutePoint(routePoint));
      thunkAPI.dispatch(addStopToRoute({
        routeStopId: `executed-${routeId}`,
        stopId: routePoint.id,
      }));
      thereIsNewStop = true;
    }
  });
  if (thereIsNewStop) {
    thunkAPI.dispatch(relateRoutePoints({ routeId: routeId }));
  }
}

const trackersSlice = createSlice({
  name: 'trackers',
  initialState,
  reducers: {
    createTracker: trackersAdapter.addOne
  }
});

export const {
  createTracker
} = trackersSlice.actions;

export default trackersSlice.reducer;

export const {
  selectAll: selectAllTrackers,
  selectById: selectTrackerById,
  selectIds: selectTrackerIds,
  selectTotal: selectTotalTrackers
} = trackersAdapter.getSelectors(state => state.routes);
