import length from '@turf/length';
import distance from '@turf/distance';

export function measureDist(geojson) {
  return  geojson.features.reduce((acc, feature) => {
    if (feature.geometry.type === "LineString") {
      const lineDistance = length(feature);
      return acc + lineDistance;
    }
    return acc;
  }, 0);
}

export function distancePoints(point1, point2) {
  // Points must be in (lng, lat) format
  const p1 = [point1[1], point1[0]];
  const p2 = [point2[1], point1[0]];
  return distance(p1, p2, { units: 'meters'});
}

export function measureTime(routePoints) {
  if (routePoints.length === 0) {
    return 0;
  }
  const firstRP = routePoints[0];
  const lastRp = routePoints[routePoints.length - 1];
  return lastRp.departureTime - firstRP.arrivalTime;
}
