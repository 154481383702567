const cities = [
  {
    title: 'Zaragoza',
    name: 'zaragoza',
    origin: 'https://api-dev.zaragoza.senator.apps.deustotech.eu',
    position: [41.6524570502108, -0.88998426790685],
    zoomLevel: 13
  },
  {
    title: 'Dublin',
    name: 'dublin',
    origin: 'https://api-dev.dublin.senator.apps.deustotech.eu',
    position: [53.344492486112, -6.2483239173889],
    zoomLevel: 15
  },
  {
    title: 'Bilbao',
    name: 'bilbao',
    origin: 'https://api-dev.bilbao.senator.apps.deustotech.eu',
    position: [43.263079170923, -2.9350368392980],
    zoomLevel: 15
  },
  {
    title: 'Pilot',
    name: 'pilot',
    origin: 'https://api.zaragoza.senator.apps.deustotech.eu',
    position: [41.6524570502108, -0.88998426790685],
    zoomLevel: 13
  }
];

if (['localhost', '10.45.0.1'].indexOf(window.location.hostname) !== -1) {
  cities.push({
    title: 'Test',
    name: 'test',
    origin: window.location.hostname === 'localhost'
      ? 'http://localhost:8008'
      : 'http://10.45.0.1:8008',
    position: [41.6524570502108, -0.88998426790685],
    zoomLevel: 13
  });
}

export default cities;
