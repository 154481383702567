import { SenatorAPI } from '../../../services/SenatorAPI';
import { colors } from './colors';
import {
  createRoute,
  setInfoMessage,
  updateLoadingRoutes 
} from '../routesSlice';
import { createRouteNavigation } from '../navigationsSlice';
import { createRouteStops } from '../stopsSlice';
import {
  createRoutePoints,
  relateRoutePoints
} from '../../routePoints/routePointsSlice';
import { addRouteToVehicle } from '../../transports/vehiclesSlice';
import {
  createTransport,
  updateRouteLocation
} from '../../transports/transportsSlice';
import { createShipments } from '../../shipments/shipmentsSlice';
import * as geo from './geo';
export { colors };

function generateVoidGeoJSON () {
  return { type: 'FeatureCollection', features: [] };
}

function getTransportIconFile (modelType) {
  switch(modelType) {
    case 'Trolley': return '/mailman.jpg';
    case 'Large Van': return '/van.svg';
    case 'Small Van': return '/smallvan.svg';
    case 'Motorcycle': return '/motorbike.png';
    default: return '/van.svg';
  }
}

export const processDeliveryAction = (action) => {
  return {
    shipment: action.shipment,
    code: action.shipmentCode,
    routeId: action.routeId,
    routeOrder: action.routeOrder,
    actionType: action.actionType,
    actionClass: action.actionClass,
    actionStatus: action.actionStatus,
    substatus: action.substatus,
    date: action.date
  }
}

export const processRoutePoint = (stop, typeName, routeName) => {
  const deliveryActions = stop.deliveryActions.map(action => {
    return processDeliveryAction(action);
  });

  return {
    id: `${typeName}-${stop.routeId}-${stop.routeOrder}`,
    routeId: stop.routeId,
    routeOrder: stop.routeOrder,
    routeType: stop.routeType,
    routeName: routeName,
    arrivalTime: stop.arrivalTime,
    departureTime: stop.departureTime,
    arrivalCapacity: stop.arrivalCapacity,
    departureCapacity: stop.departureCapacity,
    relatedRoutePoint: null,
    actions: deliveryActions,
    position: [stop.lat, stop.lng]
  }
}

export const generateRoute = async (
  route, journey, routeType, colorIndex, dispatch
) => {
  console.debug("Fetching route's info", route.id, "colorIndex: ", colorIndex);
  const [navigation, stops] = await SenatorAPI.fetchRouteInfo(
    route.id, routeType
  );
  const transport = route.transportation;
  console.debug("Fetching (1)", route.id, colorIndex, colorIndex % colors.length);
  const routeColor = {...colors[colorIndex % colors.length]};
  console.debug("Fetching (2)", route.id, colorIndex, routeColor);
  routeColor.filter = routeColor.filter.filter;
  console.debug("Fetching (3)", route.id, colorIndex, routeColor);
  const routeName = transport.licensePlate
    ? `${transport.model.name} - ${transport.licensePlate}`
    : `Postman #${transport.id}`;
  const plannedRoutePoints = stops.routePoints.map(stop => {
    return processRoutePoint(stop, 'planned', routeName);
  });
  // Generate route
  dispatch(createRoute({
    id: route.id,
    name: routeName,
    color: routeColor,
    trackerId: route.tracker ? route.tracker.id : null,
    transportId: transport.id,
    depot: transport.startDepot,
    shift: route.shift,
    journey: journey
  }));
  // Generate navigation
  dispatch(createRouteNavigation({
    id: `planned-${route.id}`,
    routeId: route.id,
    navigation: navigation,
    distance: route.totalDistance,
    key: 0
  }));
  // Generate stops
  dispatch(createRouteStops({
    id: `planned-${route.id}`,
    stops: plannedRoutePoints.map(stop => stop.id)
  }));
  dispatch(createRoutePoints(plannedRoutePoints));
  // dispatch new transportation
  const startHour = new Date(plannedRoutePoints[0].arrivalTime).getHours();
  transport.currentRoute = route.id;
  transport.routes = [[startHour, route.id]];
  transport.position = null;
  transport.icon = getTransportIconFile(transport.model.type);
  // Dispatch vehicle
  console.log("About to dispatch addRouteToVehicle", transport, transport.licensePlate);
  if (transport.licensePlate) {
    dispatch(addRouteToVehicle({
      id: transport.licensePlate,
      transportId: transport.id,
      currentRoute: route.id,
      routes: transport.routes
    }));
  }
  // Dispatch create transport
  dispatch(createTransport(transport));
  // Create shipments
  const shipments = [];
  plannedRoutePoints.forEach(routePoint => {
    const rpShipments = routePoint.actions.map(action => {
      return {
        id: action.code,
        shipmentId: action.shipment,
        actions: [{
          date: action.date,
          routeId: action.routeId,
          actionType: action.actionType,
          actionStatus: action.actionStatus,
          substatus: action.substatus
        }]
      };
    });
    shipments.push(...rpShipments);
  });
  dispatch(createShipments(shipments));
  //Inform
  dispatch(setInfoMessage({
    title: 'New route',
    message: `The route '${routeName}' has been loaded.`
  }));
  dispatch(updateLoadingRoutes());
}

export const updateRoute = async (route, dispatch) => {
  try {
    const [execNav, execStops] = await SenatorAPI.fetchRouteInfo(
      route.id, 'executed'
    );
    const transport = route.transportation;
    const routeName = transport.licensePlate
      ? `${transport.model.name} - ${transport.licensePlate}`
      : `Postman #${transport.id}`;
    const executedRoutePoints = execStops.routePoints.map(stop => {
      return processRoutePoint(stop, 'executed', routeName);
    });
    // Generate navigation
    dispatch(createRouteNavigation({
      id: `executed-${route.id}`,
      routeId: route.id,
      navigation: execNav,
      distance: execNav ? geo.measureDist(execNav) : 0,
      key: 0
    }));
    // Generate stops
    dispatch(createRouteStops({
      id: `executed-${route.id}`,
      stops: executedRoutePoints.map(stop => stop.id)
    }));
    dispatch(createRoutePoints(executedRoutePoints));
    dispatch(relateRoutePoints({ routeId: route.id }));
    // Update route location
    const lastFeature = execNav.features[execNav.features.length - 1];
    const lastCoordinates = lastFeature.geometry.coordinates;
    const position = lastCoordinates[lastCoordinates.length - 1];
    dispatch(updateRouteLocation({
      transportId: transport.id,
      routeId: route.id,
      position: [position[1], position[0]]
    }));
  } catch(err) {
    // Generate navigation
    dispatch(createRouteNavigation({
      id: `executed-${route.id}`,
      routeId: route.id,
      navigation: generateVoidGeoJSON(),
      distance: 0,
      time: 0,
      key: 0
    }));
    // Generate stops
    dispatch(createRouteStops({
      id: `executed-${route.id}`,
      stops: []
    }));
  };
  dispatch(updateLoadingRoutes());
}

const checkSameDAs = (plannedActions, executedActions) => {
  if (plannedActions.length !== executedActions.length) {
    return false;
  }

  const set1 = new Set(plannedActions.map(action => {
    return JSON.stringify([action.actionClass, action.shipment]);
  }));
  const set2 = new Set(executedActions.map(action => {
    return JSON.stringify([action.actionClass, action.shipment]);
  }));

  if (set1.size !== set2.size) {
    return false;
  }

  for (const tuple of set1)   {
    if (!set2.has(tuple)) {
      return false;
    }
  }

  return true;
}

const checkExecDAsAreInPlannedDAs = (plannedActions, executedActions) => {
  const set1 = new Set(plannedActions.map(action => {
    return JSON.stringify([action.actionClass, action.shipment]);
  }));
  const set2 = new Set(executedActions.map(action => {
    return JSON.stringify([action.actionClass, action.shipment]);
  }));

  return Array.from(set2).every(tuple => set1.has(tuple));
}

const checkPlannedDAsAreInExecDAs = (plannedActions, executedActions) => {
  return checkExecDAsAreInPlannedDAs(executedActions, plannedActions);
}

export const relateExecutedStops = (plannedStops, executedStops) => {
  let execIndex = 0;
  let planIndex = 0;
  let shouldContinue = true;
  const rId = plannedStops.length > 0 ? plannedStops[0].routeId : -1;

  console.debug("Analizing related RPs in", plannedStops[0].routeId);
  while (execIndex < executedStops.length &&
      planIndex < plannedStops.length && shouldContinue) {
    const planStop = plannedStops[planIndex];
    const execStop = executedStops[execIndex];
    const planDAs = planStop.actions;
    const execDAs = execStop.actions;
    const dist = geo.distancePoints(planStop.position, execStop.position);
    
    if (checkSameDAs(planDAs, execDAs)) {
      if (dist < 20) {
        // Same location and same actions (length), it is the same route point
        execStop.relatedRoutePoint = `planned-${rId}-${planStop.routeOrder}`;
        console.debug(
          planStop.routeId, execIndex+1, 'related to', planIndex+1, 'Same loc & same actions'
        );
        execIndex++;
        planIndex++;
      } else {
        // Same actions in stop, it is the same route point
        execStop.relatedRoutePoint = `planned-${rId}-${planStop.routeOrder}`;
        console.debug(
          planStop.routeId, execIndex+1, 'related to', planIndex+1, 'Same actions'
        );
        execIndex++;
        planIndex++;
      }
    } else if (dist > 20 && execDAs.length === 0) {
      // Void executed stop
      console.debug(planStop.routeId, execIndex+1, 'void stop');
      execIndex++;
    } else if (executedStops.length - execIndex > 1) {
      if (checkExecDAsAreInPlannedDAs(planDAs, execDAs)) {
        // If executed actions are contained in planned RP's actions,
        // the RPs are related
        execStop.relatedRoutePoint = `planned-${rId}-${planStop.routeOrder}`;
        console.debug(
          planStop.routeId, execIndex+1, 'related to', planIndex+1, 'Contains actions'
        );
        execIndex++;
      } else if (checkPlannedDAsAreInExecDAs(planDAs, execDAs)) {
        execStop.relatedRoutePoint = `planned-${rId}-${planStop.routeOrder}`;
        console.debug(
          planStop.routeId, execIndex+1, 'related to', planIndex+1, 'actions in Planned'
        );
        planIndex++;
      } else if (execStop.relatedRoutePoint) {
        console.debug(planStop.routeId, execIndex+1, 'is confirmed to', planIndex);
        execIndex++;
      } else {
        console.debug(
          planStop.routeId, execIndex+1, 'NOT related to', planIndex+1, 'No contains same actions'
        );
        if (execIndex > 0 &&
            executedStops[execIndex - 1].relatedRoutePoint === `planned-${rId}-${planStop.routeOrder}`) {
          console.debug(planStop.routeId, 'confirm planned stop to last executed action');
          planIndex++;
        } else {
          console.debug(planStop.routeId, 'abort matching route points');
          break;
        }
      }
    } else if (executedStops.length - execIndex === 1 &&
        checkExecDAsAreInPlannedDAs(planDAs, execDAs)) {
        execStop.relatedRoutePoint = `planned-${rId}-${planStop.routeOrder}`;
        console.debug(
          planStop.routeId, execIndex+1, 'related to', planIndex+1, 'action in progress'
        );
        planIndex++;
        execIndex++;
    } else if (executedStops.length - execIndex === 1 &&
        plannedStops.length - planIndex === 1 &&
        checkExecDAsAreInPlannedDAs(planDAs, execDAs)) {
        execStop.relatedRoutePoint = `planned-${rId}-${planStop.routeOrder}`;
        console.debug(
          planStop.routeId, execIndex+1, 'related to', planIndex+1, 'last action && actions in Planned'
        );
        planIndex++;
        execIndex++;
    } else if (executedStops.length - execIndex === 1 &&
        plannedStops.length - planIndex === 1 &&
        planDAs.length === 0) {
        execStop.relatedRoutePoint = `planned-${rId}-${planStop.routeOrder}`;
        console.debug(
          planStop.routeId, execIndex+1, 'related to', planIndex+1, 'last action && unloading'
        );
        planIndex++;
        execIndex++;
    } else if (executedStops.length - execIndex === 1 && 
        checkPlannedDAsAreInExecDAs(planDAs, execDAs)) {
        execStop.relatedRoutePoint = `planned-${rId}-${planStop.routeOrder}`;
        console.debug(
          planStop.routeId, execIndex+1, 'related to', planIndex+1,
          'last action && actions in Planned && unloading'
        );
        planIndex++;
    } else {
      console.debug(planStop.routeId, execIndex+1, 'NOT related to', planIndex+1, 'ELSE');
      console.debug(planStop.routeId, execIndex+1, "dist", dist, "plan.l", planDAs.length, "exec.l", execDAs.length, "inside", checkExecDAsAreInPlannedDAs(planDAs, execDAs));
      shouldContinue = false;
    }
  }
}
