import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Navigate, Routes } from 'react-router-dom';

import Box from '@mui/material/Box';

import ErrorMessage from '../components/ErrorMessage';
import LateralMainBar from '../components/LateralMainBar';
import LateralMainFab from '../components/LateralMainFab';

import Alerts from './Alerts';
import OPM from './OPM';
import MFM from './MFM';
import SRM from './SRM';
import DUI from './DUI';

import { loadDepots } from '../features/depots/depotsSlice';
import { loadULL} from '../features/layers/layersSlice';
import { loadRestrictions } from '../features/restrictions/restrictionsSlice';
import { createUser } from '../features/users/usersSlice';
import { SenatorAPI } from '../services/SenatorAPI';

const SenatorLayout = ({ city, dispatch, children }) => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  console.log("SenatorLayout", city, children);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  }

  useEffect (() => {
    SenatorAPI.origin = city.origin;
    document.title = `SENATOR - ${city.title}`;
    dispatch(loadDepots());
    dispatch(loadULL());
    dispatch(loadRestrictions());
    SenatorAPI.getUserInfo().then(userInfo => {
      dispatch(createUser(userInfo));
    });
  }, [city, dispatch]);

  return (
    <Box display="flex" flexDirection="row" sx={{
      height: "100vh",
      width: "100vw"
    }} >
      <LateralMainBar isMenuVisible={isMenuVisible} toggleMenu={toggleMenu} />
      <ErrorMessage />
      <Box
        display="flex"
        flexDirection="column"
        className="map-container"
        flexGrow={1}
        zIndex={0}
      >
        <LateralMainFab isMenuVisible={isMenuVisible} toggleMenu={toggleMenu} />
        <Routes>
          <Route key="alerts" path='alerts' element={<Alerts city={city} />} />
          <Route key="opm" path='opm' element={<OPM city={city} />} />
          <Route key="mfm" path='mfm' element={<MFM city={city} />} />
          <Route key="srm" path='srm' element={<SRM city={city} />} />
          <Route key="dui" path='dui' element={<DUI city={city} />} />
          <Route key="rest" path='*' element={<Navigate to="srm" />} />
        </Routes>
      </Box>
    </Box>
  )
};

export default connect()(SenatorLayout);
