import { useSelector } from 'react-redux';
import { selectRestrictionIds } from './restrictionsSlice';
import { Restriction } from './Restriction';

export const RestrictionList = () => {
  const restrictionIds = useSelector(selectRestrictionIds);

  return (
    restrictionIds.map((restrictionId) => (
      <Restriction key={restrictionId} restrictionId={restrictionId} />
    ))
  );
};
