import { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Panel, PanelGroup } from "react-resizable-panels";

import { selectUser } from '../features/users/usersSlice';
import { selectSelectedJourney } from '../features/routes/journeysSlice';
import {
  loadRoutes,
  setInfoMessage,
  selectLoadStatus
} from '../features/routes/routesSlice';

import MapComponent from '../components/Map';
import LoadingOverlay from '../components/LoadingOverlay';
import InfoMessage from '../components/InfoMessage';
import TransparentTopBar from '../components/TransparentTopBar';
import ToBeImplemented from '../components/ToBeImplemented';
import ResizeHandle from '../components/ResizeHandle';

import { RouteList } from '../features/routes/RouteList';

import { SenatorBus } from '../services/SenatorBus';

const getWindowWidth = () => {
  return window.innerWidth;
};

const SRM = ({ city, dispatch }) => {
  const rightRef = useRef(null);
  const bottomRef = useRef(null);
  const loadStatus = useSelector(selectLoadStatus);
  const journey = useSelector(selectSelectedJourney);
  const user = useSelector(selectUser);
  const isLoaded = loadStatus !== 'loading';
  const [mapWidth, setMapWidth] = useState(100);
  const [showRight, setShowRight] = useState(false);
  const [showBottom, setShowBottom] = useState(false);
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());
  const [mediaQueries, setMediaQueries] = useState({
    bar: {
      isXSScreen: false,
      isXXSScreen: false,
      showAllCombos: false
    },
    combos: {
      isXSScreen: false,
      isXXSScreen: false,
      showAllCombos: false
    }
  });
  console.log("SRM LoadStatus", loadStatus, user, mapWidth);

  const showKPIs = () => {
    const show = !showRight;
    setShowRight(show);
    const panel = rightRef.current;
    if (panel) {
      if (show) {
        panel.expand();
      } else {
        panel.collapse();
      }
    }
  };

  const showTable = () => {
    const show = !showBottom;
    setShowBottom(show);
    const panel = bottomRef.current;
    if (panel) {
      if (show) {
        panel.expand();
      } else {
        panel.collapse();
      }
    }
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(getWindowWidth());

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const width = windowWidth * mapWidth / 100;
    const newQueries = {
      bar: {
        isXSScreen: width < 540,
        isXXSScreen: width < 270,
        showAllCombos: width >= 1315
      },
      combos: {
        isXSScreen: width < 580,
        isXXSScreen: width < 540,
        showAllCombos: width < 775
      }
    };
    console.log("Resize", windowWidth, mapWidth, width, newQueries);
    setMediaQueries(newQueries);
  }, [windowWidth, mapWidth]);

  useEffect (() => {
    dispatch(loadRoutes());

    return () => dispatch(setInfoMessage({ title: '', message: '' }));
  }, [journey, dispatch]);

  useEffect (() => {
    const connectToBusIfRequired = async () => {
      if (loadStatus !== 'loading' && user && !SenatorBus.isConnected()) {
        // Check if we must really start the BUS (same day)
        console.log("SRM connecting to Senator BUS");
        SenatorBus.connect(
          user.bus.user, user.bus.pass, true, user.bus.path
        );
      }
    }

    // connect to bus if required
    connectToBusIfRequired();

    return () => {
      console.log("SRM Unloading Use Effect");
      SenatorBus.disconnect();      
    };
  }, [loadStatus, user])

  return (
    <>
      <LoadingOverlay />
      {loadStatus === 'succeeded' && (<InfoMessage />)}
      <PanelGroup direction="horizontal">
        <Panel
          className="data-panel"
          defaultSize={100}
          minSize={25}
          order={1}
          onResize={(size) => setMapWidth(size)}
        >
          <PanelGroup direction="vertical">
            <Panel
              className="data-panel"
              defaultSize={100}
              minSize={25}
              order={1}
            >
              <MapComponent position={city.position} zoomLevel={city.zoomLevel} >
                {isLoaded &&
                  <TransparentTopBar
                    mediaQueries={mediaQueries}
                    showKPIs={{ click: showKPIs, shown: showRight }}
                    showTable={{ click: showTable, shown: showBottom }}
                  />
                }
                <RouteList />
              </MapComponent>
            </Panel>
            <ResizeHandle
              id="horizontal-handle"
              direction="horizontal"
              type="internal"
              show={showBottom}
            />
            <Panel
              className="data-panel"
              ref={bottomRef}
              minSize={25}
              defaultSize={0}
              order={2}
              collapsible
              onCollapse={(c) => setShowBottom(!c)}
            >
              <ToBeImplemented background="white" />
            </Panel>
          </PanelGroup>
        </Panel>
        <ResizeHandle id="vertical-handle" direction="vertical" show={showRight}/>
        <Panel
          className="data-panel"
          ref={rightRef}
          minSize={15}
          defaultSize={0}
          order={2}
          collapsible
          onCollapse={(c) => setShowRight(!c)}
        >
          <ToBeImplemented background="whitesmoke"/>
        </Panel>
      </PanelGroup>
    </>
  );
}

export default connect()(SRM);
