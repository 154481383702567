import Fab from '@mui/material/Fab';
import Avatar from '@mui/material/Avatar';
import Fade from '@mui/material/Fade';

const LayersIcon = ({ showIcon, handleClick }) => {

  return (
    <Fade in={showIcon} transition={500}>
      <Fab
        aria-label="layers-menu"
        color="white"
        size="medium"
        onClick={handleClick}
        sx={{
          m: 1,
          backgroundColor: 'white',
          position: "absolute",
          top: 'auto',
          bottom: 20,
          left: 'auto',
          right: 2,
          borderRadius: "20%",
          borderColor: "primary.main",
          borderStyle: "solid",
          zIndex: 9999
        }}
      >
          <Avatar
           src="/images/layers.svg"
           alt="Layers"
           imgProps={{
             sx: {
               objectFit: 'fill',
               transition: "transform 0.1s",
               '&:hover': {
                 transform: "scale(0.9)",
                 borderWidth: "2px"
               }
             }
           }}
           variant="square"
          />
      </Fab>
    </Fade>
  );
}

export default LayersIcon;
