import * as L from 'leaflet';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { GeoJSON, useMapEvent } from 'react-leaflet';

import { SenatorAPI } from '../../services/SenatorAPI';
import { selectShowDepots } from '../../features/depots/depotsSlice';
import {
  selectULL,
  selectShowULL,
  selectShowLoadingAreas
} from '../../features/layers/layersSlice';
import {
  selectShowRestrictions
} from '../../features/restrictions/restrictionsSlice';
import { DepotList } from '../../features/depots/DepotList';
import { RestrictionList } from '../../features/restrictions/RestrictionList';


const DepotsLayer = () => {
  const showDepots = useSelector(selectShowDepots);
  const depots = <DepotList />

  return showDepots && depots;
};

const UrbanLivingLabLayer = () => {
  const showULL = useSelector(selectShowULL);
  const ull = useSelector(selectULL);

  return showULL && (
    <GeoJSON data={ull} style={{ color: "#00a874", zIndex: 1 }} pane="ULL"/>
  );
};

const RestrictionsLayer = () => {
  const showRestrictions = useSelector(selectShowRestrictions);
  const restrictions = <RestrictionList />

  return showRestrictions && restrictions;
};

const LoadingAreasLayer = () => {
  const [loadingAreas, setLoadingAreas] = useState(null);
  const [key, setKey] = useState(0);
  const showLoadingAreas = useSelector(selectShowLoadingAreas);
  const map = useMapEvent('move', async () => {
    const bounds = map.getBounds();
    const areas = await SenatorAPI.fetchLoadingAreas(bounds);
    setLoadingAreas(areas);
    setKey(key => key + 1);
  });

  return showLoadingAreas && (
    <GeoJSON
      key={key}
      data={loadingAreas}
      pointToLayer={(feat, latlng) => {
        return L.circleMarker(latlng);
      }}
      onEachFeature={(feat, layer) => {
        let html = '';
        Object.entries(feat.properties).forEach(entry => {
          html += `<div><b>${entry[0]}:</b>${entry[1]}</div>`;
        });
        layer.bindTooltip(`<div>${html}</div>`);
      }}
      pane="LoadingAreas"
    />
  );
};


const ExtraLayers = () => {

  return (
    <>
      <UrbanLivingLabLayer />
      <RestrictionsLayer />
      <LoadingAreasLayer />
      <DepotsLayer />
    </>
  );
};

export default ExtraLayers;
