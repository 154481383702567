import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { setColorIndex, selectRouteIds } from './routesSlice';

const journeysAdapter = createEntityAdapter();
const initialState = journeysAdapter.getInitialState({
  selectedJourney: new Date().toISOString().split('T')[0]
});

export const setSelectedJourney = createAsyncThunk(
  'journeys/setSelectedJourney',
  async (journey, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const journeyData = state.journeys.entities[journey];
      if (journeyData) {
        thunkAPI.dispatch(setColorIndex(journeyData.length));
      } else {
        thunkAPI.dispatch(setColorIndex(0));
      }
      thunkAPI.dispatch(setJourney(journey));
    } catch(err) {
      console.error("setSelectedJourney", err);
    }
  }
);

const journeysSlice = createSlice({
  name: 'journeys',
  initialState,
  reducers: {
    createJourney: journeysAdapter.addOne,
    deleteJourneys: journeysAdapter.removeMany,
    addRouteToJourney (state, action) {
      const { journeyId, routeId } = action.payload;
      const journey = state.entities[journeyId];
      if (journey) {
        journey.routes.push(routeId);
      } else {
        console.error(`Journey '${journeyId}' does not exist`);
      }
    },
    setJourney (state, action) {
      state.selectedJourney = action.payload.journey;
    }
  },
  extraReducers(builder) {
    builder
      .addCase('routes/initializeLoading', (state, action) => {
        state.selectedJourney = new Date().toISOString().split('T')[0];
      })
  }
});

export const {
  setJourney,
  createJourney,
  deleteJourneys,
  selectedJourney,
  addRouteToJourney,
} = journeysSlice.actions;

export default journeysSlice.reducer;

export const {
  selectAll: selectAllJourneys,
  selectById: selectJourneyById,
  selectIds: selectJourneyIds,
  selectTotal: selectLoadedJourneys
} = journeysAdapter.getSelectors(state => state.journeys);

export const selectSelectedJourney = state => state.journeys.selectedJourney;

export const selectJourneyRoutes = createSelector(
  (state, journeyId) => selectJourneyById(state, journeyId),
  (journey) => {
    if (journey) {
      return journey.routes;
    }
    return [];
  }
);

export const selectCurrentJourney = createSelector(
  state => selectJourneyById(state, state.journeys.selectedJourney),
  (journey) => {
    return journey;
  }
);

export const selectCurrentJourneyRouteIds = createSelector(
  state => state.routes.status === 'loading',
  selectCurrentJourney,
  (isLoading, journey) => {
    if (isLoading) {
      const numRoutes = journey.routes.length;
      if (numRoutes > 5) {
        return journey.routes.slice(0, 5 + Math.floor(Math.log(numRoutes)));
      }
    }
    return journey ? journey.routes : [];
  }
)

export const selectRouteIdsConditional = createSelector(
  selectRouteIds,
  selectCurrentJourneyRouteIds,
  (routeIds, currentJourneyRoutes) => {
    return routeIds.filter(routeId => {
      return currentJourneyRoutes.indexOf(routeId) !== -1;
    });
  }
);
