import { PanelResizeHandle } from "react-resizable-panels";

import styles from "./styles.module.css";

const ResizeHandle = ({
  className = "", type = "external", direction, id, show
}) => {
  const directionClass = direction === 'horizontal'
    ? styles.HandlerHorizontal
    : styles.HandlerVertical;
  const outTypeClass = type === "external"
    ? styles.ResizeHandleOuterExternal
    : styles.ResizeHandleOuterInternal;
  const inTypeClass = type === "external"
    ? styles.ResizeHandleInnerExternal
    : styles.ResizeHandleInnerInternal;
  const outerStyle = show ? styles.ResizeHandleOuter : styles.HideHandler;

  return (
    <PanelResizeHandle
      className={[outerStyle, outTypeClass, className].join(" ")}
      id={id}
    >
      <div className={[styles.ResizeHandleInner, inTypeClass].join(" ")}>
        <div className={[styles.Handler, directionClass].join(" ")} />
      </div>
    </PanelResizeHandle>
  );
};

export default ResizeHandle;
