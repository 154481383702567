import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit'
import { SenatorAPI } from '../../services/SenatorAPI';
import * as utils from './utils';
import { deleteStops, calculateDensity, createRouteStops } from './stopsSlice';
import {
  deleteRoutePoints,
  createRoutePoints
} from '../routePoints/routePointsSlice';
import {
  deleteJourneys,
  addRouteToJourney,
  selectJourneyRoutes
} from './journeysSlice';
import {
  updateRouteNavigation,
  deleteRouteNavigations
} from './navigationsSlice';
import { createJourney } from './journeysSlice';
import { createTracker } from '../transports/trackersSlice';
import { updateTransportsRoutes } from '../transports/transportsSlice';
import { persistors } from '../../app/store';


const routesAdapter = createEntityAdapter();
const allRoutesSpecialRoute = { id: 'All', name: 'All', color: { color: 'transparent' }};

const initialState = routesAdapter.getInitialState({
  colorIndex: 0,
  loadingRoutes: {
    toLoad: 0,
    loaded: 0
  },
  status: 'idle',
  isUpdating: false,
  error: {
    title: '',
    message: ''
  },
  info: {
    title: '',
    message: ''
  },
  hoverId: '',
  showPlanned: true,
  showExecuted: true
});

export const cleanOldRoutes = createAsyncThunk(
  'routes/cleanOldRoutes',
  async (journey, thunkAPI) => {
    try {
      console.log("cleanOldRoutes", journey);
      const state = thunkAPI.getState();
      const allJourneys = [...state.journeys.ids].sort();
      const keepJourneys = [
        journey, ...allJourneys.slice(allJourneys.length-3, allJourneys.length)
      ];
      const journeysToDelete = allJourneys.filter(j => {
        return keepJourneys.indexOf(j) === -1
      });
      const routesToDelete = [];
      journeysToDelete.forEach(journey => {
        routesToDelete.push(...state.journeys.entities[journey].routes);
      });
      const navigationsToDelete = [];
      const stopsToDelete = [];
      const routePointsToDelete = [];
      routesToDelete.forEach(routeId => {
        navigationsToDelete.push(...state.navigations.ids.filter(nId => {
          return nId === `planned-${routeId}` || nId === `executed-${routeId}`;
        }));
        stopsToDelete.push(...state.navigations.ids.filter(sId => {
          return sId === `planned-${routeId}` || sId === `executed-${routeId}`;
        }));
        routePointsToDelete.push(...state.navigations.ids.filter(pId => {
          return pId === `planned-${routeId}` || pId === `executed-${routeId}`;
        }));
      });
      console.log("cleanOldRoutes routePointsToDelete", routePointsToDelete);
      thunkAPI.dispatch(deleteRoutePoints(routePointsToDelete));
      console.log("cleanOldRoutes stopsToDelete", stopsToDelete);
      thunkAPI.dispatch(deleteStops(stopsToDelete));
      console.log("cleanOldRoutes navToDelete", navigationsToDelete);
      thunkAPI.dispatch(deleteRouteNavigations(navigationsToDelete));
      console.log("cleanOldRoutes routesToDelete", routesToDelete);
      thunkAPI.dispatch(deleteRoutes(routesToDelete));
      console.log("cleanOldRoutes journeysToDelete", journeysToDelete);
      thunkAPI.dispatch(deleteJourneys(journeysToDelete));
      console.log("cleanOldRoutes finished", journey);
    } catch(err) {
      console.error("cleanOldRoutes", err);
      throw err;
    }
  }
);

export const fetchRoutes = createAsyncThunk(
  'routes/fetchRoutes',
  async (journey, thunkAPI) => {    
    try {
      console.log("fetchRoutes", journey);
      const state = thunkAPI.getState();
      // Pause the persistance of the state
      persistors[state.users.city.title].pause();

      // Check if journey it is today or not
      const today = (new Date()).toISOString().split('T')[0];
      const plannedType = journey === today ? 'actual' : 'planned';
      // Fetch the list of routes
      const retrievedRoutes = await SenatorAPI.fetchRoutes(journey);
      // Existing routes
      const existingRoutes = state.routes.ids;
      // Filter already existing routes
      const routes = retrievedRoutes.filter(route => {
        return existingRoutes.indexOf(route.id) === -1;
      });
      // Update the number of routes that will be processed
      thunkAPI.dispatch(setTotalRoutes(routes.length * 2 + existingRoutes.length));
      // Sort the routes by distance
      routes.sort(({totalDistance:a}, {totalDistance:b}) => b-a);
      // Create routes
      let colorIndex = state.routes.colorIndex;
      const generatePromises = routes.map((route) => {
        // Create route
        thunkAPI.dispatch(addRouteToJourney({
          journeyId: journey,
          routeId: route.id
        }));
        // increment color index
        thunkAPI.dispatch(incrementColorIndex());
        // dispatch new tracker (if it exists)
        if (route.tracker) {
          thunkAPI.dispatch(createTracker({
            id: route.tracker.externalId,
            trackerId: route.tracker.id,
            routeId: route.id
          }));
        }
        // Generate route
        return utils.generateRoute(
          route, journey, plannedType, colorIndex++, thunkAPI.dispatch
        );
      });
      await Promise.all(generatePromises);
      // Update with info from the executed route
      const updatePromises = routes.map((route) => {
        return utils.updateRoute(route, thunkAPI.dispatch);
      });
      await Promise.all(updatePromises);
      // Dispatch update transportations
      thunkAPI.dispatch(updateTransportsRoutes());
      // Show info
      thunkAPI.dispatch(setInfoMessage({
        title: 'Routes loaded',
        message: `${routes.length} routes have been loaded for day '${journey}'`
      }));
      // Clean old routes
      thunkAPI.dispatch(cleanOldRoutes(journey));
      // Recalculate density
      thunkAPI.dispatch(calculateDensity());
      // Activate again the persistance of the state
      persistors[state.users.city.title].persist();
      return "Finished";
    } catch(err) {
      console.error("fetchRoutes", err);
      // return thunkAPI.rejectWithValue(err);
      throw err;
    }
  }
);

export const loadRoutes = createAsyncThunk(
  'routes/loadRoutes',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      if (!state.routes.isUpdating) {
        thunkAPI.dispatch(startRoutesUpdate());
        const journey = state.journeys.selectedJourney;
        console.log("loadRoutes", journey);

        // Check if it is a new journey
        if (state.journeys.ids.indexOf(journey) === -1) {
          // If it is a new journey =>
          // Create the journey
          thunkAPI.dispatch(createJourney({ id: journey, routes: [] }));
          thunkAPI.dispatch(setTotalRoutes(0));
          // Fetch routes
          await thunkAPI.dispatch(fetchRoutes(journey));
          thunkAPI.dispatch(finishRoutesUpdate());
        } else {
          // If there is cached data for that journey => update the info
          thunkAPI.dispatch(finishRoutesUpdate());
          thunkAPI.dispatch(updateRoutes());
        }
      }
    } catch (err) {
      console.error("loadRoutes", err);
    }
  }
);

export const newRoute = createAsyncThunk(
  'routes/newRoute',
  async ({ routeId, journey }, thunkAPI) => {
    try {
      console.log("newRoute", routeId, journey);
      // Retrieve route
      const route = await SenatorAPI.fetchRoute(routeId);
      // Get route color
      const colorIndex = thunkAPI.getState().routes.colorIndex;
      thunkAPI.dispatch(incrementColorIndex());
      // Set route type
      const today = (new Date()).toISOString().split('T')[0];
      const plannedType = journey === today ? 'actual' : 'planned';
      // Add route to journey
      thunkAPI.dispatch(addRouteToJourney({
        journeyId: journey,
        routeId: routeId
      }));
      // dispatch new tracker (if it exists)
      if (route.tracker) {
        thunkAPI.dispatch(createTracker({
          id: route.tracker.externalId,
          trackerId: route.tracker.id,
          routeId: route.id
        }));
      }
      // Generate route
      console.log("newRoute", "call to generateRoute");
      await utils.generateRoute(
        route, journey, plannedType, colorIndex, thunkAPI.dispatch
      );
      // Update with executed info (just in case)
      await utils.updateRoute(route, thunkAPI.dispatch);
      // Finish
      thunkAPI.dispatch(setInfoMessage({
        title: 'Routes loaded',
        message: `Route ${routeId} has been loaded in '${journey}'`
      }));
      return "Finished";
    } catch (err) {
      console.error("newRoute", err);
    }
  }
);

export const updateRoutes = createAsyncThunk(
  'routes/updateRoutes',
  async (_, thunkAPI) => {
    try {
      if (!thunkAPI.getState().routes.isUpdating) {
        thunkAPI.dispatch(startRoutesUpdate());
        console.log("updateRoutes");
        const state = thunkAPI.getState();
        const journey = state.journeys.selectedJourney;
        // Fetch the list of routes
        const retrievedRoutes = await SenatorAPI.fetchRoutes(journey);
        // Iterate routes
        const promises = retrievedRoutes.map(async (route) => {
          // Check if it is a new route
          if (state.routes.ids.indexOf(route.id) === -1) {
            return thunkAPI.dispatch(newRoute({
              routeId: route.id,
              journey: journey
            }));
          }
          // If it is an existing route -> update it
          return updateRoute(route.id, thunkAPI);
        });
        await Promise.all(promises);
        console.log("updateRoutes: finished")
        thunkAPI.dispatch(finishRoutesUpdate());
      }
    } catch (err) {
      console.error("updateRoutes", err);
    }
  }
);

export const updateRoute = async (routeId, thunkAPI) => {
  try {
    console.log("updateRoute", routeId);
    const state = thunkAPI.getState();
    const oldStops = state.stops.ids.filter(s => s.endsWith(`-$(routeId)`));
    const oldRoutePoints = state.routePoints.ids.filter(rp => {
      return rp.startsWith(`planned-$(routeId)-`);
    });
    const route = state.routes.entities[routeId];
    const [plannedNav, plannedStops] = await SenatorAPI.fetchRouteInfo( 
      routeId, "actual"
    );
    const [executedNav, executedStops] = await SenatorAPI.fetchRouteInfo( 
      routeId, "executed"
    );
    // Generate new stops
    const plannedRoutePoints = plannedStops.routePoints.map(stop => {
      return utils.processRoutePoint(stop, 'planned', route.name);
    });
    const executedRoutePoints = executedStops.routePoints.map(stop => {
      return utils.processRoutePoint(stop, 'executed', route.name);
    });
    utils.relateExecutedStops(plannedRoutePoints, executedRoutePoints);
    // Generate navigation
    thunkAPI.dispatch(updateRouteNavigation({
      routeNavId: `planned-${routeId}`,
      routeId: routeId,
      navigation: plannedNav
    }));
    // Generate navigation
    thunkAPI.dispatch(updateRouteNavigation({
      routeNavId: `executed-${routeId}`,
      routeId: routeId,
      navigation: executedNav
    }));
    // Delete old stops
    thunkAPI.dispatch(deleteStops(oldStops));
    thunkAPI.dispatch(deleteRoutePoints(oldRoutePoints));
    // Create planned stops
    thunkAPI.dispatch(createRouteStops({
      id: `planned-${routeId}`,
      stops: plannedRoutePoints.map(stop => stop.id)
    }));
    thunkAPI.dispatch(createRoutePoints(plannedRoutePoints));
    // Create executed stops
    thunkAPI.dispatch(createRouteStops({
      id: `executed-${routeId}`,
      stops: executedRoutePoints.map(stop => stop.id)
    }));
    thunkAPI.dispatch(createRoutePoints(executedRoutePoints));
    console.log("updateRoute", routeId, "finished");
  } catch (err) {
    console.error("updateRoute", err);
  }
}

export const updatePlannedRoute = createAsyncThunk(
  'routes/updatePlannedRoute',
  async (routeId, thunkAPI) => {
    try {
      console.log("updatePlannedRoute", routeId);
      const state = thunkAPI.getState();
      const oldStops = state.stops.ids.filter(s => {
        return s.endsWith(`planned-$(routeId)`);
      });
      const oldRoutePoints = state.routePoints.ids.filter(rp => {
        return (
          rp.startsWith(`planned-$(routeId)-`) ||
          rp.startsWith(`executed -$(routeId)-`)
        );
      });
      // Check if it exists first
      const route = state.routes.entities[routeId];
      if (route) {
        const routeName = route.name;
        const [navigation, stops] = await SenatorAPI.fetchRouteInfo( 
          routeId, "actual"
        );
        // Generate stops
        const plannedRoutePoints = stops.routePoints.map(stop => {
          return utils.processRoutePoint(stop, 'planned', routeName);
        });
        // Generate navigation
        thunkAPI.dispatch(updateRouteNavigation({
          routeNavId: `planned-${routeId}`,
          routeId: routeId,
          navigation: navigation,
        }));
        thunkAPI.dispatch(deleteStops(oldStops));
        thunkAPI.dispatch(deleteRoutePoints(oldRoutePoints));
        thunkAPI.dispatch(createRouteStops({
          id: `planned-${routeId}`,
          stops: plannedRoutePoints.map(stop => stop.id)
        }));
        thunkAPI.dispatch(createRoutePoints(plannedRoutePoints));
      } else {
        // If the route does not exist, create it
        thunkAPI.dispatch(newRoute({
          routeId: routeId,
          journey: (new Date()).toISOString().split('T')[0]
        }));
      }
    } catch (err) {
      console.error("updatePlannedRoute", err);
    }
  }
);

const routesSlice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    deleteRoutes: routesAdapter.removeMany,
    initializeLoading (state, action) {
      state.status = 'idle';
      state.isUpdating = false;
      state.error.title = '';
      state.error.message = '';
      state.info.title = '';
      state.info.message = '';
    },
    setColorIndex (state, action) {
      state.colorIndex = action.payload;
    },
    incrementColorIndex (state, action) {
      state.colorIndex++;
    },
    setTotalRoutes (state, action) {
      state.loadingRoutes.toLoad = action.payload;
      state.loadingRoutes.loaded = 0;
    },
    updateLoadingRoutes (state, action) {
      state.loadingRoutes.loaded++;
    },
    createRoute (state, action) {
      try {
      const route = action.payload;
      if (route.color == null) {
        console.log("createRoute (1) Route.color null?", route.color);
        route.color = Object.assign(
          utils.colors[state.colorIndex++ % utils.colors.length]
        );
        route.color.filter = route.color.filter.filter;
      }
      routesAdapter.addOne(state, action.payload);
      } catch(err) {
        console.error("createRoute", err);
      }
    },
    setHoverRouteId (state, action) {
      state.hoverId = action.payload.hover;
    },
    setInfoMessage (state, action) {
      const { title, message } = action.payload;
      state.info.title = title;
      state.info.message = message;
    },
    setErrorMessage (state, action) {
      const { title, message } = action.payload;
      state.error.title = title;
      state.error.message = message;
    },
    startRoutesUpdate (state, action) {
      state.isUpdating = true;
    },
    finishRoutesUpdate (state, action) {
      state.isUpdating = false;
    },
    setShowPlannedRoutes (state, action) {
      state.showPlanned = action.payload
    },
    setShowExecutedRoutes (state, action) {
      state.showExecuted = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRoutes.pending, (state, action) => {
        console.log("Loading start", state.status);
        state.status = 'loading';
      })
      .addCase(fetchRoutes.fulfilled, (state, action) => {
        console.log("Loading end", state.status);
        state.status = 'succeeded';
      })
      .addCase(fetchRoutes.rejected, (state, action) => {
        state.status = 'failed';
        state.error.title = 'Error';
        state.error.message = action.error.message;
        console.log(action.error, action.error.message);
        console.error("fetchRoutes.rejected", state.error);
      })
  }
});

export const {
  setDensity,
  createRoute,
  deleteRoutes,
  setColorIndex,
  setTotalRoutes,
  setInfoMessage,
  setErrorMessage,
  setHoverRouteId,
  startRoutesUpdate,
  setShowPlannedRoutes,
  setShowExecutedRoutes,
  initializeLoading,
  finishRoutesUpdate,
  incrementColorIndex,
  updateExecutedRoute,
  updateExecutedStops,
  updateLoadingRoutes,
  updateRouteLastLocation
} = routesSlice.actions;

export default routesSlice.reducer;

export const {
  selectAll: selectAllRoutes,
  selectEntities: selectRoutesDict,
  selectById: selectRouteById,
  selectIds: selectRouteIds,
  selectTotal: selectLoadedRoutes
} = routesAdapter.getSelectors(state => state.routes);

export const selectLoadStatus = state => state.routes.status;
export const selectInfoMessage = state => state.routes.info;
export const selectErrorMessage = state => state.routes.error;
export const selectHoverRouteId = state => state.routes.hoverId;
export const selectShowStops = state => state.routes.showStops;

export const selectShowPlannedRoutes = state => state.routes.showPlanned;
export const selectShowExecutedRoutes = state => state.routes.showExecuted;

export const selectLoadingProgress = createSelector(
  state => state.routes.loadingRoutes.loaded,
  state => state.routes.loadingRoutes.toLoad,
  (loadedRoutes, routesToLoad) => {
    console.log("selectLoadingProgress", loadedRoutes, routesToLoad);
    return (loadedRoutes / routesToLoad) * 100;
  }
);

export const selectRouteIdsConditional2 = createSelector(
  selectLoadStatus,
  selectRouteIds,
  state => state.routes.loadingRoutes.toLoad,
  (loadStatus, routeIds) => {
    const numRoutes = routeIds.length;
    if (loadStatus === 'loading') {
      if (numRoutes > 5 && numRoutes % 10 !== 0) {
        return routeIds.slice(
          0, Math.max(5, Math.floor(routeIds.length/10) * 10)
        );
      }
    }
    return routeIds;
  }
);

export const selectProgressPosition = createSelector(
  state => state.routes.loadingRoutes.loaded,
  state => state.routes.loadingRoutes.toLoad,
  (loadedRoutes, routesToLoad) => {
    if (routesToLoad > 0) {
      return loadedRoutes / routesToLoad * 100;
    }
    return 0;
  }
)

export const selectSelectedRoute = createSelector(
  selectRoutesDict,
  state => state.stops.selectedId,
  (routesDict, routeId) => {
    console.log("selectSelectedRoute", routesDict, routeId);
    if (routeId !== 'All') {
      return routesDict[routeId];
    }
    return allRoutesSpecialRoute;
  }
);

export const selectRoutesByIds = createSelector(
  selectRoutesDict,
  (state, routeIds) => routeIds,
  (routesDict, routeIds) => routeIds.map(routeId => routesDict[routeId])
);

export const selectRoutesByJourney = createSelector(
  selectRoutesDict,
  (state, journey) => selectJourneyRoutes(state, journey),
  (routesDict, routeIds) => {
    const routes = routeIds
      .filter(routeId => routeId in routesDict)
      .map(routeId => routesDict[routeId]);
    return routes;
  }
);

export const selectRoutesByJourneyAndDepot = createSelector(
  (state, journey) => selectRoutesByJourney(state, journey),
  (state, journey, depotId) => depotId,
  (routes, depotId) => {
    console.log("selectRoutesByJourneyAndDepot", routes, depotId);
    if (depotId !== 'All') {
      return routes.filter(route => route.depot === depotId);
    }
    return routes;
  }
);

export const selectRoutesByJourneyDepotAndShift = createSelector(
  (state, journey, depotId) => {
    return selectRoutesByJourneyAndDepot(state, journey, depotId);
  },
  (state, journey, depotId, shift) => shift,
  (routes, shift) => {
    console.log("selectRoutesByJourneyDepotAndShift", routes, shift);
    if (shift !== 'All') {
      return routes.filter(route => route.shift === shift);
    }
    return routes;
  }
);

export const selectAvailableRoutes = createSelector(
  state => selectRoutesByJourneyDepotAndShift(
    state,
    state.journeys.selectedJourney,
    state.depots.selectedDepot,
    state.stops.selectedShift
  ),
  (routes) => {
    console.log("selectAvailableRoutes", routes);
    if (routes.length > 1) {
      return [allRoutesSpecialRoute, ...routes];
    }
    return routes;
  }
);

export const selectIsInAllAvailableRoutes = createSelector(
  state => selectRoutesByJourneyDepotAndShift(
    state,
    state.journeys.selectedJourney,
    state.depots.selectedDepot,
    state.stops.selectedShift
  ),
  (state, routeId) => routeId,
  (routes, routeId) => {
    return routes.filter(route => route.id === routeId).length === 1;
  }
);
