import {
  createSlice,
  createSelector,
  createEntityAdapter
} from '@reduxjs/toolkit'
import * as utils from '../routes/utils';

const routePointsAdapter = createEntityAdapter();
const initialState = routePointsAdapter.getInitialState();

const getStopsByRouteId = (routePoints, routeType, routeId) => {
  const stopId = `${routeType}-${routeId}-`;
  return routePoints
    .filter(rp => rp.id.startsWith(stopId))
    .sort((a, b) => a.routeOrder <= b.routeOrder ? -1 : 1);
}

const performRoutePointRelation = (routePoints, routeId) => {
  console.log("relateRoutePoints: performing");
  const plannedStops = getStopsByRouteId(routePoints, 'planned', routeId);
  const executedStops = getStopsByRouteId(routePoints, 'executed', routeId);
  utils.relateExecutedStops(plannedStops, executedStops);
  console.log("relateRoutePoints: finished");
}

const routePointsSlice = createSlice({
  name: 'routePoints',
  initialState,
  reducers: {
    createRoutePoints: routePointsAdapter.addMany,
    deleteRoutePoints: routePointsAdapter.removeMany,
    addRoutePoint: routePointsAdapter.addOne,
    updateRoutePointPosition (state, action) {
      const { routePointId, position } = action.payload;
      const routePoint = state.entities[routePointId];
      if (routePoint) {
        routePoint.position = position;
      } else {
        console.error(`Route Point '${routePointId}' does not exist`);
      }
    },
    updateRelatedRoutePoint (state, action) {
      const { routePointId, relatedRoutePoint } = action.payload;
      const routePoint = state.entities[routePointId];
      if (routePoint) {
        if (routePoint.relatedRP !== relatedRoutePoint) {
          routePoint.relatedRP = relatedRoutePoint;
        }
      } else {
        console.error(`Route Point '${routePointId}' does not exist`);
      }
    },
    relateRoutePoints (state, action) {
      try {
      console.log("relateRoutePoints: processing");
      const { routeId } = action.payload;
      console.log("relateRoutePoints: routeId", routeId);
      performRoutePointRelation(Object.values(state.entities), routeId);
      } catch (err) {
        console.log("relateRoutePoints: ", err);
      }
    },
    addDeliveryAction (state, action) {
      const { routePointId, deliveryAction } = action.payload;
      const routePoint = state.entities[routePointId];
      if (routePoint) {
        routePoint.actions.push(deliveryAction);
      } else {
        console.error(`Route Point '${routePointId}' does not exist`);
      }
    },
    updateExecutedRoutePoints (state, action) {
      const { routeId, routeName, stops } = action.payload;
      let thereIsNewStop = false;
      stops.routePoints.forEach(stop => {
        const newPosition = [stop.lat, stop.lng];
        const routePointId = `executed-${routeId}-${stop.routeOrder}`;
        const existingStop = state.entities[routePointId];
        if (existingStop) {
          // Update position if required
          if (existingStop.position[0] !== newPosition[0] ||
              existingStop.position[1] !== newPosition[1]) {
            existingStop.position = newPosition;
          }
          if (existingStop.actions.length !== stop.deliveryActions.length) {
            const receivedActions = stop.deliveryActions.map(action => {
              return utils.processDeliveryAction(action);
            });
            const existingDAs = existingStop.actions.map(action => {
              return action.shipment;
            });
            const newActions = receivedActions.filter(action => {
              return existingDAs.indexOf(action.shipment) === -1;
            });
            newActions.forEach(action => {
              existingStop.actions.push(action);
            });
          }
        } else {
          routePointsAdapter.addOne(
            utils.processRoutePoint(stop, 'executed', routeName)
          );
          thereIsNewStop = true;
        }
      });
      if (thereIsNewStop) {
        performRoutePointRelation(Object.values(state.entities), routeId);
      }
    }
  }
});

export const {
  addRoutePoint,
  relateRoutePoints,
  addDeliveryAction,
  createRoutePoints,
  deleteRoutePoints,
  updateRelatedRoutePoint,
  updateRoutePointPosition
} = routePointsSlice.actions;

export default routePointsSlice.reducer;

export const {
  selectAll: selectAllRoutePoints,
  selectById: selectRoutePointById,
  selectIds: selectRoutePointIds,
  selectTotal: selectLoadedRoutePoints
} = routePointsAdapter.getSelectors(state => state.routePoints);

export const selectRelatedRP = (state, relatedRoutePointId) => {
  if (relatedRoutePointId) {
    return state.routePoints.entities[relatedRoutePointId];
  }
  return null;
}

export const selectAllPlannedRoutePoints = createSelector(
  selectAllRoutePoints,
  (routePoints) => {
    console.log("Calculated selectAllPlannedRoutePoints", routePoints.length);
    return routePoints.filter(rp => rp.id.startsWith('planned'));
  }
);

export const selectPlannedRoutePointsPerRoute = createSelector(
  selectAllPlannedRoutePoints,
  (state, routeId) => routeId,
  (plannedRoutePoints, routeId) => {
    if (routeId === 'All') {
      return plannedRoutePoints;
    }
    return plannedRoutePoints.filter(rp => rp.routeId === routeId);
  }
);

export const selectRelatedActions = createSelector(
  state => state.routePoints.entities,
  (state, routePoint) => routePoint,
  (routePoints, routePoint) => {
    // For planned route points, just retrieve the planned actions
    if (routePoint.id.startsWith('planned')) {
      return routePoint.actions;
    }
    // For executed route points, get the executed actions
    const rpActions = routePoint.actions;
    if (routePoint.relatedRoutePoint) {
      // And complete the list of actions with the actions of the related
      // planned route point that has not been performed yet
      const relatedRP = routePoints[routePoint.relatedRoutePoint];
      const relatedActions = relatedRP.actions;
      if (relatedActions.length > 0) {
        const newActions = [...rpActions];
        const usedShipments = newActions.map(action => action.shipment);
        newActions.push(...relatedActions.filter(action => {
          return usedShipments.indexOf(action.shipment) === -1
        }));
        return newActions;
      }
      return rpActions;
    }
    return rpActions;
  }
);
