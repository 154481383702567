import { useSelector } from 'react-redux';
import { TileLayer } from 'react-leaflet';

import tiles from '../../config/tiles';
import { selectSelectedTile } from '../../features/layers/layersSlice';

const TileLayers = () => {
  const selectedTile = useSelector(selectSelectedTile);
  const tile = tiles[selectedTile];

  return (
    <TileLayer
      attribution={tile.attribution}
      url={tile.url}
      maxZoom={tile.maxZoom}
      subdomains={tile.subdomains ? tile.subdomains : 'a'}
    />
  );
};

export default TileLayers;
