import { connect, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {
  setSelectedJourney,
  selectSelectedJourney
} from '../../features/routes/journeysSlice';

const ComboJourneys = ({ isGrouped, radius, dispatch }) => {
  const selectedJourney = useSelector(selectSelectedJourney);

  return (
    <DesktopDatePicker
      id="combo-box-journeys"
      value={dayjs(selectedJourney)}
      label={isGrouped ? "" : "Date"}
      fullWidth
      sx={{
        height: 48,
        "& .MuiAutocomplete-popupIndicator": { transform: "none" }
      }}
      slotProps={{
        textField: {
          variant: "outlined", 
          sx: {
            width: '100%',
            "& .MuiOutlinedInput-root": {
              background: "white",
              height: '48px',
              borderTopRightRadius: radius,
              borderBottomRightRadius: radius,
              borderTopLeftRadius: isGrouped ? 0 : radius,
              borderBottomLeftRadius: isGrouped ? 0 : radius,
            },
            "& .MuiInputLabel-root": {
              transform: 'translate(18px, 4px) scale(0.75)'
            },
            "& .MuiOutlinedInput-notchedOutline>legend>span": {
              display: isGrouped ? "inline-block" : "none"
            },
            "& .MuiOutlinedInput-root .MuiInputBase-inputAdornedEnd": {
              paddingLeft: isGrouped ? "9px" : "18px",
              paddingBottom: isGrouped ? "7.5px" : "0px",
              paddingTop: isGrouped ? "7.5px" : "15px"
            }
          }
        }
      }}
      onChange={(newValue) => {
        console.log("ComboJourney onChange", newValue);
        dispatch(setSelectedJourney({ journey: newValue.format('YYYY-MM-DD') }));
      }}
    />
  );
}

export default connect()(ComboJourneys);
