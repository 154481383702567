import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

const MFMIconButton = ({ size, place }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const selected = location.pathname.endsWith('/mfm');
  const showTooltip = !selected && open;

  useEffect(() => {
    if (selected) {
      setOpen(false);
    }
  }, [selected]);

  return (
    <Tooltip
      open={showTooltip}
      title="Multi-fleet Manager"
      placement={place}
      arrow
      disableHoverListener={selected}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <span>
        <IconButton
          aria-label="MFM"
          size={size}
          disabled={selected}
          onClick={() => navigate("mfm")}
        >
          <LocalShippingOutlinedIcon color={selected ? "primary" : ""} />
        </IconButton>
      </span>
    </Tooltip>
  );
}

export default MFMIconButton;
