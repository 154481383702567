import { connect, useSelector } from 'react-redux';

import { useMediaQuery } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';

import MapIcon from '@mui/icons-material/Map';
import PinDropIcon from '@mui/icons-material/PinDrop';
import RoundaboutRightIcon from '@mui/icons-material/RoundaboutRight';

import {
  setShowPlannedRoutes,
  selectShowPlannedRoutes
} from '../../features/routes/routesSlice';
import {
  setShowPlannedStops,
  selectShowPlannedStops
} from '../../features/routes/stopsSlice';
import {
  setShowPlannedNav,
  selectShowPlannedNav
} from '../../features/routes/navigationsSlice';

const OptionPath = ({ disabled, dispatch }) => {
  const showPlannedNav = useSelector(selectShowPlannedNav);
  
  return (
    <ListItem sx={{ p: 0, pl: 4 }} disabled={disabled}>
      <ListItemIcon>
        <RoundaboutRightIcon />
      </ListItemIcon>
      <ListItemText primary="Path" />
      <Checkbox
        edge="end"
        checked={showPlannedNav}
        inputProps={{ 'aria-labelledby': 'show-planned-path' }}
        onChange={() => dispatch(setShowPlannedNav(!showPlannedNav))}
      />
    </ListItem>
  );
}

const OptionStops = ({ disabled, dispatch }) => {
  const showPlannedStops = useSelector(selectShowPlannedStops);
  const isXSScreen = useMediaQuery('(max-width:322px)');
  
  const handleChange = (_, newValue) => {
    dispatch(setShowPlannedStops(newValue));
  }

  return (
    <ListItem
      sx={{ p: 0, pl: 4, alignItems: "flex-start" }}
      disabled={disabled}
    >
      <ListItemIcon>
        <PinDropIcon />
      </ListItemIcon>
      <FormControl component="div">
        <ListItemText primary="Stops" />
        <RadioGroup
          row
          value={showPlannedStops}
          onChange={handleChange}
          sx={{
            flexWrap: isXSScreen ? 'wrap' : 'nowrap',
            justifyContent: 'flex-end'
          }}
        >
          <FormControlLabel
            value="never"
            control={<Radio />}
            label="never"
            labelPlacement="start"
          />
          <FormControlLabel
            value="density"
            control={<Radio />}
            label="density"
            labelPlacement="start"
          />
          <FormControlLabel
            value="always"
            control={<Radio />}
            label="always"
            labelPlacement="start"
          />
        </RadioGroup>
      </FormControl>
    </ListItem>
  );
}

const OptionsPlanned = ({ dispatch }) => {
  const showPlanned = useSelector(selectShowPlannedRoutes);

  return (
    <>
      <ListItem sx={{ p: 0 }} >
        <ListItemIcon>
          <MapIcon />
        </ListItemIcon>
        <ListItemText primary="Planned route" />
        <Checkbox
          edge="end"
          checked={showPlanned}
          inputProps={{ 'aria-labelledby': 'show-planned' }}
          onChange={() => dispatch(setShowPlannedRoutes(!showPlanned))}
        />
      </ListItem>
      <List component="div" disablePadding >
        <OptionPath disabled={!showPlanned} dispatch={dispatch} />
        <OptionStops disabled={!showPlanned} dispatch={dispatch} />
      </List>
    </>
  );
}

export default connect()(OptionsPlanned);
