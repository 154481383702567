import jwt_decode from 'jwt-decode';
import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit'
import { persistors } from '../../app/store';
import { resetState } from '../../app/reducers';
import { SenatorAPI } from '../../services/SenatorAPI';

const usersAdapter = createEntityAdapter();
const initialState = usersAdapter.getInitialState({
  token: null,
  city: null
});

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    createUser: usersAdapter.upsertOne,
    setToken (state, action) {
      state.token = action.payload.token;
      console.log("User setToken", action.payload.token);
    },
    setCity (state, action) {
      state.city = action.payload.city;
    }
  }
});

export const clearStateAndExit = createAsyncThunk(
  'users/cleanStateAndExit',
  async (_, thunkAPI) => {
    try {
      console.log("cleanStateAndExit");
      const state = thunkAPI.getState();
      thunkAPI.dispatch(resetState());
      persistors[state.users.city.title].purge();
      SenatorAPI.logout();
    } catch(err) {
      console.error("cleanStateAndExit", err);
      throw err;
    }
  }
);

export const {
  logout,
  setCity,
  setToken,
  createUser
} = usersSlice.actions;

export const {
  selectAll: selectAllUsers,
  selectById: selectUserById,
  selectIds: selectUserIds,
  selectTotal: selectTotalUsers
} = usersAdapter.getSelectors(state => state.users);

export default usersSlice.reducer;

export const selectCity = state => state.users.city;
export const selectOrigin = state => state.users.city.origin;
export const selectUser = createSelector(
  selectAllUsers,
  (users) => users[0]
);
export const selectToken = createSelector(
  state => state.users.token,
  (token) => {
    if (token) {
      return jwt_decode(token.idToken);
    }
    return null;
  }
);
