import { forwardRef } from 'react';
import Draggable from 'react-draggable';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import OptionsPlanned from './OptionsPlanned';
import OptionsExecuted from './OptionsExecuted';

const PaperComponent = forwardRef((props, ref) => (
  <Draggable
    handle="#draggable-dialog-title"
    cancel={'[class*="MuiDialogContent-root"], .no-drag'}
    bounds="parent"
  >
    <Paper ref={ref} {...props} />
  </Draggable>
));

const SRMOptions = ({ open, handleClose, fullscreen }) => {
  console.log("SRMOptions", fullscreen);

  return (
    <Dialog
      fullScreen={fullscreen}
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        style={{ cursor: 'move' }}
        id="draggable-dialog-title"
      >
        {fullscreen ? "Settings" : "Smart-Route Manager Settings"}
        <IconButton
          aria-label="close"
          className='no-drag'
          onClick={handleClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'gray',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <List sx={{ width: '100%' }}>
          <OptionsPlanned />
          <Divider />
          <OptionsExecuted />
        </List>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SRMOptions;
