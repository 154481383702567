import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { Alert, AlertTitle, Snackbar } from '@mui/material';

import {
  selectLoadStatus,
  selectErrorMessage
} from '../features/routes/routesSlice';

const ErrorMessage = () => {
  const errorMessage = useSelector(selectErrorMessage);
  const loadStatus = useSelector(selectLoadStatus);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect (() => {
    if (loadStatus === 'failed') {
      setOpenAlert(true);
    }

  }, [loadStatus])

  return loadStatus === 'failed' && (
    <Snackbar
      open={openAlert}
      onClose={() => {setOpenAlert(false);}}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ minWidth: '25%' }}
    >
      <Alert
        variant="outlined"
        severity="error"
        sx={{ width: '100%', bgcolor: '#ffeff1' }}
        onClose={() => {setOpenAlert(false);}}
      >
        <AlertTitle>{errorMessage.title}</AlertTitle>
        {errorMessage.message}
      </Alert>
    </Snackbar>
  );
}

export default ErrorMessage;
