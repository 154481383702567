/**
 * Set bound to 6 decimals
 */
export const normalizeBounds = (mapBounds) => {
  const northWest = mapBounds.getNorthWest();
  const southEast = mapBounds.getSouthEast();
  const northWestLat = parseFloat(northWest.lat.toFixed(6));
  const northWestLng = parseFloat(northWest.lng.toFixed(6));
  const southEastLat = parseFloat(southEast.lat.toFixed(6));
  const southEastLng = parseFloat(southEast.lng.toFixed(6));
  return {
    northWestLat: northWestLat,
    northWestLng: northWestLng,
    southEastLat: southEastLat,
    southEastLng: southEastLng
  };
}
