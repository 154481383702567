import { useSelector, useDispatch } from 'react-redux';
import { useMapEvents }  from 'react-leaflet';
import { selectRouteIdsConditional } from './journeysSlice';
import {
  setMovingStatus,
  setMapPixelSize,
  recalculateDensity
} from './stopsSlice';
import { Route } from './Route';
import { normalizeBounds } from '../../utils';

export const RouteList = () => {
  const dispatch = useDispatch();
  const routeIds = useSelector(selectRouteIdsConditional);
  console.log("Calculated in RouteList", routeIds.length);

  function MapChangeEvents () {
    const map = useMapEvents({
      resize: () => {
        const size = map.getSize();
        dispatch(setMapPixelSize({ x: size.x, y: size.y }));
        console.log("Calculated event resize", size);
      },
      zoomstart: () => {
        dispatch(setMovingStatus());
        console.log("Calculated event zoomstart");
      },
      zoomend: () => {
        console.log("Calculated event zoomevent");
        const size = map.getSize();
        dispatch(recalculateDensity({
          bounds: normalizeBounds(map.getBounds()),
          size: { x: size.x, y: size.y }
        }));
      },
      movestart: () => {
        // dispatch(setMovingStatus());
        // console.log("Calculated event movestart");
      },
      moveend: () => {
        console.log("Calculated event moveend");
        const size = map.getSize();
        dispatch(recalculateDensity({
          bounds: normalizeBounds(map.getBounds()),
          size: { x: size.x, y: size.y }
        }));
      }
    });
    return null;
  }

  return (
    <>
      <MapChangeEvents />
      {routeIds.map((routeId) => (
        <Route key={routeId} routeId={routeId} />
      ))}
    </>
  );
}
