import { connect, useSelector } from 'react-redux';

import { useMediaQuery } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';

import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import PinDropIcon from '@mui/icons-material/PinDrop';
import RoundaboutRightIcon from '@mui/icons-material/RoundaboutRight';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import {
  setShowExecutedRoutes,
  selectShowExecutedRoutes
} from '../../features/routes/routesSlice';
import {
  setShowExecutedStops,
  selectShowExecutedStops
} from '../../features/routes/stopsSlice';
import {
  setShowExecutedNav,
  selectShowExecutedNav
} from '../../features/routes/navigationsSlice';
import {
  setShowTransports,
  selectShowTransports
} from '../../features/transports/transportsSlice';

const OptionPath = ({ disabled, dispatch }) => {
  const showExecutedNav = useSelector(selectShowExecutedNav);

  return (
    <ListItem sx={{ p: 0, pl: 4 }} disabled={disabled}>
      <ListItemIcon>
        <RoundaboutRightIcon />
      </ListItemIcon>
      <ListItemText primary="Path" />
      <Checkbox
        edge="end"
        checked={showExecutedNav}
        inputProps={{ 'aria-labelledby': 'show-executed-path' }}
        onChange={() => dispatch(setShowExecutedNav(!showExecutedNav))}
      />
    </ListItem>
  );
}

const OptionStops = ({ disabled, dispatch }) => {
  const showExecutedStops = useSelector(selectShowExecutedStops);
  const isXSScreen = useMediaQuery('(max-width:322px)');

  const handleChange = (_, newValue) => {
    dispatch(setShowExecutedStops(newValue));
  }

  return (
    <ListItem
      sx={{ p: 0, pl: 4, alignItems: "flex-start" }}
      disabled={disabled}
    >
      <ListItemIcon>
        <PinDropIcon />
      </ListItemIcon>
      <FormControl>
        <ListItemText primary="Stops" />
        <RadioGroup
          row
          value={showExecutedStops}
          onChange={handleChange}
          sx={{
            flexWrap: isXSScreen ? 'wrap' : 'nowrap',
            justifyContent: 'flex-end'
          }}
        >
          <FormControlLabel
            value="never"
            control={<Radio />}
            label="never"
            labelPlacement="start"
          />
          <FormControlLabel
            value="density"
            control={<Radio />}
            label="density"
            labelPlacement="start"
          />
          <FormControlLabel
            value="always"
            control={<Radio />}
            label="always"
            labelPlacement="start"
          />
        </RadioGroup>
      </FormControl>
    </ListItem>
  );
}

const OptionTransportation = ({ disabled, dispatch }) => {
  const showTransports = useSelector(selectShowTransports);
  const isXSScreen = useMediaQuery('(max-width:270px)');
  const isXXSScreen = useMediaQuery('(max-width:240px)');

  let label = "Transportation"
  if (isXXSScreen) {
    label = "Transp.";
  } else if (isXSScreen) {
    label = "Transport";
  }
  
  return (
    <ListItem sx={{ p: 0, pl: 4 }} disabled={disabled}>
      <ListItemIcon>
        <LocalShippingIcon />
      </ListItemIcon>
      <ListItemText primary={label} />
      <Checkbox
        edge="end"
        checked={showTransports}
        inputProps={{ 'aria-labelledby': 'show-executed-transport' }}
        onChange={() => dispatch(setShowTransports(!showTransports))}
      />
    </ListItem>
  );
}

const OptionsExecuted = ({ dispatch }) => {
  const showExecuted = useSelector(selectShowExecutedRoutes);

  return (
    <>
      <ListItem sx={{ p: 0 }} >
        <ListItemIcon>
          <DirectionsRunIcon />
        </ListItemIcon>
        <ListItemText primary="Executed route" />
        <Checkbox
          edge="end"
          checked={showExecuted}
          inputProps={{ 'aria-labelledby': 'show-executed' }}
          onChange={() => dispatch(setShowExecutedRoutes(!showExecuted))}
        />
      </ListItem>
      <List component="div" disablePadding >
        <OptionPath disabled={!showExecuted} dispatch={dispatch} />
        <OptionStops disabled={!showExecuted} dispatch={dispatch} />
        <OptionTransportation disabled={!showExecuted} dispatch={dispatch} />
      </List>
    </>
  );
}

export default connect()(OptionsExecuted);
