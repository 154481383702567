import Fab from '@mui/material/Fab';
import Avatar from '@mui/material/Avatar';
import Zoom from '@mui/material/Zoom';
import { useMediaQuery } from '@mui/material';

const LateralMainFab = ({ isMenuVisible, toggleMenu }) => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const showFab = isSmallScreen && !isMenuVisible;
  console.log("MenuBar Fab", isMenuVisible, isSmallScreen, showFab);

  return (
    <Zoom in={showFab}>
    <Fab
      aria-label="menu"
      color="white"
      size="medium"
      onClick={toggleMenu}
      sx={{
        m: 1,
        backgroundColor: 'white',
        position: "absolute",
        borderRadius: "20%",
        borderColor: "primary.main",
        borderStyle: "solid",
        zIndex: 99999
      }}
    >
        <Avatar
         src="/senator.svg"
         alt="Logo"
         imgProps={{
           sx: {
             objectFit: 'fill',
             transition: "transform 0.1s",
             '&:hover': {
               transform: "scale(0.9)",
               borderWidth: "2px"
             }
           }
         }}
         variant="square"
        />
    </Fab>
      </Zoom>
  );
}

export default LateralMainFab;
