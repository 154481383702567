import { useState, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import Stack from '@mui/material/Stack';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import RouteIcon from '@mui/icons-material/Route';
import GroupIcon from '@mui/icons-material/Group';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Box from '@mui/material/Box';

import ShrinkablePaper from './ShrinkablePaper';
import ComboJourneys from './ComboJourneys';
import ComboDepots from './ComboDepots';
import ComboShifts from './ComboShifts';
import ComboRoutes from './ComboRoutes';

const CustomInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: '23px',
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    border: '1px solid #5c5c5c',
    fontSize: 16,
    padding: '12px 26px 11px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontWeight: 'bold'
  },
  '& .MuiSelect-select': {
            borderTopLeftRadius: '23px',
            borderTopRightRadius: 0,
            borderBottomLeftRadius: '23px',
            borderBottomRightRadius: 0

    }
}));

const fullOptions = ['Date', 'Depot', 'Shift', 'Route'];
const triadOptions = ['Date', 'Depot', 'Shift'];
const SelectedCombo = ({ settings, mediaQueries, showKPIs, showTable }) => {
  const { showAllCombos, isXSScreen, isXXSScreen } = mediaQueries;
  const options = showAllCombos ? fullOptions : triadOptions;
  const [comboType, setComboType] = useState("Depot");

  useEffect(() => {
    if (!showAllCombos && comboType === "Route") {
      setComboType("Depot");
    }
  }, [comboType, showAllCombos]);

  const handleMenuItemClick = (event) => {
    setComboType(event.target.value);
  };

  return (
    <Stack
      direction="row"
      spacing={isXXSScreen ? 1 : 2}
      alignItems="center"
      sx={{minWidth: 100, mr: isXXSScreen ? 1 : 2}}
    >
    <ShrinkablePaper>
      <ButtonGroup fullWidth>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={comboType}
          onChange={handleMenuItemClick}
          input={<CustomInput />}
          sx={{
            borderRadius: '23px',
            "&:focus": {
              borderRadius: "23px"
            },
            "& .MuiInputBase-input:focus": {
              borderTopLeftRadius: "23px",
              borderBottomLeftRadius: "23px",
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
              // py: "8px"
            }
          }}
          renderValue={(p) => {
            return isXSScreen ? "" : p;
          }}
        >
        {options.map(option => (
          <MenuItem value={option} key={option}>
            <ListItemIcon>
              {option === "Date" && <InsertInvitationIcon fontSize="small"/>}
              {option === "Depot" && <WarehouseIcon fontSize="small"/>}
              {option === "Shift" && <GroupIcon fontSize="small"/>}
              {option === "Route" && <RouteIcon fontSize="small"/>}
            </ListItemIcon>
            <ListItemText>
              {option}
            </ListItemText>
          </MenuItem>
        ))}
        </Select>
        {comboType === 'Date' && <ComboJourneys isGrouped={true} radius='23px'/>}
        {comboType === 'Depot' && <ComboDepots isGrouped={true} radius='23px'/>}
        {comboType === 'Shift' && <ComboShifts isGrouped={true} radius='23px'/>}
        {comboType === 'Route' && <ComboRoutes isGrouped={true} radius='23px'/>}
      </ButtonGroup>
    </ShrinkablePaper>
    {!showAllCombos &&
      <ShrinkablePaper>
        <ComboRoutes isGrouped={false} radius='23px'/>
      </ShrinkablePaper>
    }
    {isXXSScreen && (
      <Box sx={{ width: 48, height: 48, minWidth: 36 }}> 
        <SpeedDial
          ariaLabel="Other options"
          icon={<SpeedDialIcon />}
          direction="down"
          sx={{
            position: 'absolute',
            top: 8, 
            right: 8,
            '& .MuiFab-primary': { width: 48, height: 48 }
          }}
          FabProps={{
            sx: {
              color: '#0000008a',
              bgcolor: 'white',
              '&:hover': {
                bgcolor: 'white',
                color: 'primary.main'
              }
            }
          }}
        >
          <SpeedDialAction
            key="Settings"
            icon={
              <SettingsOutlinedIcon color={settings.shown ? "primary" : ""}/>
            }
            tooltipTitle="Settings"
            onClick={settings.click}
          />
          <SpeedDialAction
            key="Table"
            icon={
              <TableChartOutlinedIcon color={showTable.shown ? "primary" : ""}/>
            }
            tooltipTitle="Table info"
            onClick={showTable.click}
          />
          <SpeedDialAction
            key="KPIs"
            icon={
              <LeaderboardOutlinedIcon color={showKPIs.shown ? "primary" : ""}/>
            }
            tooltipTitle="KPIs"
            onClick={showKPIs.click}
          />
        </SpeedDial>
      </Box> 
    )}
    </Stack>
  );
}

export default SelectedCombo;
