import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';

const SRMIconButton = ({ size, place }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const selected = location.pathname.endsWith('/srm');
  const showTooltip = !selected && open;

  useEffect(() => {
    if (selected) {
      setOpen(false);
    }
  }, [selected]);

  return (
    <Tooltip
      open={showTooltip}
      title="Smart Route Planner"
      placement={place}
      arrow
      disableHoverListener={selected}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <span>
        <IconButton
          aria-label="SRM"
          size={size}
          disabled={selected}
          onClick={() => navigate("srm")}
        >
          <RouteOutlinedIcon color={selected ? "primary" : ""} />
        </IconButton>
      </span>
    </Tooltip>
  );
}

export default SRMIconButton;
