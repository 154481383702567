import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit'
import { updateTransportLocation } from '../transports/transportsSlice';
import { updateRouteLastLocation } from '../routes/navigationsSlice';

const vehiclesAdapter = createEntityAdapter();
const initialState = vehiclesAdapter.getInitialState();

export const vehicleLocation = createAsyncThunk(
  'transports/vehicleLocation',
  async ({ vehicleId, position, hour}, thunkAPI) => {
    try {
    const state = thunkAPI.getState();
    const vehicle = state.vehicles.entities[vehicleId];
    // Dispatch the action
    thunkAPI.dispatch(updateTransportLocation({
      transportId: vehicle.transportId,
      position: position
    }));
    // Send to current Route
    const route = state.routes.entities[vehicle.currentRoute];
    // TODO Check if current route is real current route
    thunkAPI.dispatch(updateRouteLastLocation({
      routeNavId: `executed-${route.id}`,
      position: position
    }));
    } catch (err) {
      console.error("vehicleLocation", err);
    }
  }
);

const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    addRouteToVehicle (state, action) {
      try {
      const vehicle = action.payload;
      console.log("addRouteToVehicle (1)", vehicle);
      if (state.ids.indexOf(vehicle.id) !== -1) {
        state.entities[vehicle.id].routes.push(...vehicle.routes);
      } else {
        state.ids.push(vehicle.id);
        state.entities[vehicle.id] = vehicle;
      }
      } catch (err) {
        console.error("addRouteToVehicle", err);

      }
    }
  }
});

export const {
  addRouteToVehicle
} = vehiclesSlice.actions;

export default vehiclesSlice.reducer;
