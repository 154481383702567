import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { SenatorAPI } from '../../services/SenatorAPI';

const initialState = {
  showULL: false,
  showLoadingAreas: false,
  selectedTile: 1,
  ull: null
};

export const loadULL = createAsyncThunk(
  'layers/loadULL',
  async (_, thunkAPI) => {
    try {
      const ull = await SenatorAPI.fetchULL();
      thunkAPI.dispatch(setULL(ull));
    } catch (err) {
      console.error("loadULL", err);
    }
  }
);

const layersSlice = createSlice({
  name: 'layers',
  initialState,
  reducers: {
    setShowULL (state, action) {
      state.showULL = action.payload;
    },
    setShowLoadingAreas (state, action) {
      state.showLoadingAreas = action.payload;
    },
    setSelectedTile (state, action) {
      state.selectedTile = action.payload;
    },
    setULL (state, action) {
      state.ull = action.payload;
    }
  }
});

export default layersSlice.reducer;

export const {
  setULL,
  setShowULL,
  setSelectedTile,
  setShowLoadingAreas
} = layersSlice.actions;

export const selectULL = state => state.layers.ull;
export const selectShowULL = state => state.layers.showULL;
export const selectShowLoadingAreas = state => state.layers.showLoadingAreas;
export const selectSelectedTile = state => state.layers.selectedTile;
