import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import {
  selectIsInAllAvailableRoutes,
  selectRoutesByJourneyAndDepot
} from './routesSlice';
import {
  selectPlannedRoutePointsPerRoute
} from '../routePoints/routePointsSlice';

const stopsAdapter = createEntityAdapter();
const initialState = stopsAdapter.getInitialState({
  limit: 23*23,  // area of a marker
  selectedId: 'All',
  previousId: '',
  selectedShift: 'All',
  status: 'initial',
  showPlannedStops: 'density',
  showExecutedStops: 'density',
  density: 0,
  northWestLat: 0,
  northWestLng: 0,
  southEastLng: 0,
  southEastLat: 0,
  pixelsX: 0,
  pixelsY: 0
});

const performDensityRecalculation = (limit, bounds, size, stops) => {
  // Filter shown stops
  const shownStops = stops.filter(stop => {
    return (
      stop.position[0] >= bounds.southEastLat &&
      stop.position[1] >= bounds.northWestLng &&
      stop.position[0] <= bounds.northWestLat &&
      stop.position[1] <= bounds.southEastLng
    );
  });
  console.log('*calculateDensity stops/shown', stops.length, shownStops.length);
  // Calculate current map's area
  let calculatedDensity = 0;
  if (shownStops.length <= limit) {
    if (shownStops.length > 0) {
      const maxLat = shownStops.reduce(
        (max, obj) => (obj.position[0] > max ? obj.position[0] : max),
        shownStops[0].position[0]
      );
      const minLat = shownStops.reduce(
        (min, obj) => (obj.position[0] < min ? obj.position[0] : min),
        shownStops[0].position[0]
      );
      const maxLng = shownStops.reduce(
        (max, obj) => (obj.position[1] > max ? obj.position[1] : max),
        shownStops[0].position[1]
      );
      const minLng = shownStops.reduce(
        (min, obj) => (obj.position[1] < min ? obj.position[1] : min),
        shownStops[0].position[1]
      );
      const pixelArea = size.x * size.y;
      const totalArea = (bounds.northWestLat - bounds.southEastLat) *
        (bounds.southEastLng - bounds.northWestLng) * 1000000
      const mapArea = maxLat !== minLat && maxLng !== minLng
        ? (maxLat - minLat) * (maxLng - minLng) * 1000000
        : totalArea;
      console.log("*calculateDensity mapArea", mapArea, totalArea, pixelArea, mapArea*pixelArea/totalArea);

      // Calculate the density of markers on the map
      calculatedDensity =  shownStops.length > 10
        ? shownStops.length * limit / (0.6 * mapArea * pixelArea / totalArea)
        : 0.9;
      console.log("*calculateDensity density", calculatedDensity, shownStops.length, mapArea);
    }
  } else {
    calculatedDensity = shownStops.length / limit;
  }
  console.log("*calculatedDensity density (2)", calculatedDensity, shownStops.length);
  return calculatedDensity;
}

export const calculateDensity = createAsyncThunk(
  'stops/calculateDensity',
  async (_, thunkAPI) => {
    try {
      console.log('calculateDensity (0)');
      const globalState = thunkAPI.getState();
      const state = globalState.stops;
      const bounds = {
        northWestLat: state.northWestLat,
        northWestLng: state.northWestLng,
        southEastLng: state.southEastLng,
        southEastLat: state.southEastLat
      }
      const size = { x: state.pixelsX, y: state.pixelsY };
      console.log('calculateDensity (0b)', bounds, size);
      const stops = selectPlannedRoutePointsPerRoute(globalState, state.selectedId);
      console.log('calculateDensity (1)', stops.length);
      if (stops.length > 0) {
        const calculatedDensity = performDensityRecalculation(
          state.limit, bounds, size, stops
        );
        console.log("calculatedDensity density (2)", calculatedDensity);
        thunkAPI.dispatch(setDensity({ density: calculatedDensity }));
      }
    } catch (err) {
      console.error('calculateDensity', err);
    }
  }
);

export const recalculateDensity = createAsyncThunk(
  'stops/recalculateDensity',
  async ({ bounds, size }, thunkAPI) => {
    try {
      const globalState = thunkAPI.getState();
      const state = globalState.stops;
      let somethingChanged = false;
      if (
        bounds.northWestLat !== state.northWestLat ||
        bounds.northWestLng !== state.northWestLng ||
        bounds.southEastLat !== state.southEastLat ||
        bounds.southEastLng !== state.southEastLng
      ) {
        somethingChanged = true;
        await thunkAPI.dispatch(setMapBounds({ bounds: bounds }));
      }
      if (
        size.x !== state.pixelsX ||
        size.y !== state.pixelsY
      ) {
        somethingChanged = true;
        await thunkAPI.dispatch(setMapPixelSize(size));
      }
      if (somethingChanged) {     
        // Update bounds
        const stops = selectPlannedRoutePointsPerRoute(globalState, state.selectedId);
        console.log('recalculateDensity (1)', stops.length);
        if (stops.length > 0) {
          const calculatedDensity = performDensityRecalculation(
            state.limit, bounds, size, stops
          );
          console.log("recalculatedDensity density (2)", calculatedDensity);
          thunkAPI.dispatch(setDensity({ density: calculatedDensity }));
        }
      }
    } catch (err) {
      console.error('recalculateDensity', err);
    }
  }
)

export const changeLayers = createAsyncThunk(
  'stops/changeLayers',
  async ({ routeId }, thunkAPI) => {
    try {
      await thunkAPI.dispatch(setSelectedRouteId({ selected: routeId }));
      const globalState = thunkAPI.getState();
      const state = globalState.stops;
      const stops = selectPlannedRoutePointsPerRoute(globalState, routeId);
      console.log('changeLayers (1)', routeId, stops.length);
      if (stops.length > 0) {
        const bounds = {
          northWestLat: state.northWestLat,
          northWestLng: state.northWestLng,
          southEastLng: state.southEastLng,
          southEastLat: state.southEastLat
        }
        const size = { x: state.pixelsX, y: state.pixelsY };
        const calculatedDensity = performDensityRecalculation(
          state.limit, bounds, size, stops
        );
        console.log("changeLayers density (2)", calculatedDensity);
        thunkAPI.dispatch(setDensity({ density: calculatedDensity }));
      }
    } catch (err) {
      console.error('changeLayers', err);
    }
  }
);

const stopsSlice = createSlice({
  name: 'stops',
  initialState,
  reducers: {
    createRouteStops: stopsAdapter.addOne,
    deleteStops: stopsAdapter.removeMany,
    addStopToRoute (state, action) {
      const { routeStopId, stopId } = action.payload;
      const routeStops = state.entities[routeStopId];
      if (routeStops) {
        state.entities[routeStopId].stops.push(stopId);
      } else {
        console.error(`RouteStopId ${routeStopId} does not exist`);
      }
    },
    setSelectedRouteId (state, action) {
      const { selected } = action.payload;
      state.previousId = state.selectedId;
      state.selectedId = selected;
      state.status = 'layer changed';
    },
    setSelectedShift (state, action) {
      state.selectedShift = action.payload.shift;
    },
    setMapBounds (state, action) {
      try {
        const { bounds } = action.payload;
        state.northWestLat = bounds.northWestLat;
        state.northWestLng = bounds.northWestLng;
        state.southEastLat = bounds.southEastLat;
        state.southEastLng = bounds.southEastLng;
      } catch (err) {
        console.error("setMapBounds", err);
      }
    },
    setMapPixelSize (state, action) {
      try {
        const { x, y } = action.payload;
        console.log("setMapPixelSize", x, y);
        state.pixelsX = x;
        state.pixelsY = y;
      } catch (err) {
        console.error("setMapPixelSize", err);
      }
    },
    setMovingStatus (state, action) {
      state.status = 'moving';
    },
    setDensity (state, action) {
      state.density = action.payload.density;
      state.status = 'show';
    },
    setShowPlannedStops (state, action) {
      state.showPlannedStops = action.payload;
    },
    setShowExecutedStops (state, action) {
      state.showExecutedStops = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(recalculateDensity.fulfilled, (state, action) => {
        state.status = 'show';
      })
      .addCase(recalculateDensity.rejected, (state, action) => {
        state.status = 'show';
        console.error("recalculateDensityMove.rejected", state.error);
      })
      .addCase(changeLayers.fulfilled, (state, action) => {
        state.status = 'show';
      })
      .addCase(changeLayers.rejected, (state, action) => {
        state.status = 'show';
        console.error("changeLayers.rejected", state.error);
      })
      .addCase('routes/initializeLoading', (state, action) => {
        console.log("routes/initializeLoading setMapPixelSize")
        state.pixelsX = window.innerWidth;
        state.pixelsY = window.innerHeight;
        console.log("routes/initializeLoading setMapPixelSize", window.innerWidth, window.innerHeight);
      })
  }
});

export const {
  setDensity,
  deleteStops,
  setMapBounds,
  addStopToRoute,
  setMapPixelSize,
  setMovingStatus,
  setSelectedShift,
  createRouteStops,
  setSelectedRouteId,
  setShowPlannedStops,
  setShowExecutedStops
} = stopsSlice.actions;

export default stopsSlice.reducer;

export const {
  selectAll: selectAllStops,
  selectById: selectStopsById,
  selectIds: selectStopsIds,
  selectTotal: selectLoadedStops
} = stopsAdapter.getSelectors(state => state.stops);

export const selectShowPlannedStops = state => state.stops.showPlannedStops;
export const selectShowExecutedStops = state => state.stops.showExecutedStops;

export const selectScreenHeight = state => state.stops.pixelsY;
export const selectScreenWidth = state => state.stops.pixelsX;

export const selectPlannedStopsById = (state, routeId) =>
  selectStopsById(state, `planned-${routeId}`);

export const selectExecutedStopsById = (state, routeId) =>
  selectStopsById(state, `executed-${routeId}`);

export const selectStopsByTypeAndId = (state, routeType, routeId) => {
  const stopsId = `${routeType}-${routeId}`;
  return selectStopsById(state, stopsId);
}

export const selectSelectedShift = state => state.stops.selectedShift;

export const selectCheckItIsAll = createSelector(
  state => state.stops.selectedId === 'All',
  (state, routeId) => selectIsInAllAvailableRoutes(state, routeId),
  (isAll, routeIsInAll) => {
    return isAll && routeIsInAll;
  }
);

export const selectRouteRerender = createSelector(
  state => state.stops.status,
  state => state.stops.density >= 1,
  (state, routeId) => selectCheckItIsAll(state, routeId),
  (state, routeId) => state.stops.selectedId === routeId,
  (currentStatus, tooMuchDensity, isAll, isRoute) => {
    console.debug('selectRouteRerender', currentStatus, tooMuchDensity, isAll, isRoute);
    // A non-shown route
    if (!isRoute && !isAll) {
      console.debug('selectRouteRerender', currentStatus, tooMuchDensity, isAll, isRoute, "=>", 'hide stops & hide nav');
      return 'hide stops & hide nav';
    }
    // status: LAYER CHANGED
    if (currentStatus === 'layer changed') {
      // if it is the same route: show everything
      if (isRoute) {
        console.debug('selectRouteRerender', currentStatus, tooMuchDensity, isAll, isRoute, "=>", 'show stops & show nav');
        return 'show stops & show nav';
      }
      // If it is all routes: show nav but hide stops
      // return 'hide stops & show nav';
      console.debug('selectRouteRerender', currentStatus, tooMuchDensity, isAll, isRoute, "=>", 'show stops & show nav');
      return 'show stops & show nav';
    }
    // status: MOVING
    if (currentStatus === 'moving') {
      console.debug('selectRouteRerender', currentStatus, tooMuchDensity, isAll, isRoute, "=>", 'hide stops & show nav');
      return 'hide stops & show nav';
    }
    // status: SHOW
    if (currentStatus === 'show') {
      if (tooMuchDensity) {
        console.debug('selectRouteRerender', currentStatus, tooMuchDensity, isAll, isRoute, "=>", 'hide stops & show nav');
        return 'hide stops & show nav';
      }
      console.debug('selectRouteRerender', currentStatus, tooMuchDensity, isAll, isRoute, "=>", 'show stops & show nav');
      return 'show stops & show nav';
    }
    // DEFAULT (should never send this action)
    console.debug('selectRouteRerender', currentStatus, tooMuchDensity, isAll, isRoute, "=>", 'no action');
    return 'hide stops & show nav';
  }
);

export const selectNavRerender = createSelector(
  state => state.stops.status,
  (state, routeId) => selectCheckItIsAll(state, routeId),
  (state, routeId) => state.stops.selectedId === routeId,
  (state, routeId, routeType) => routeType === 'planned'
    ? state.navigations.showPlannedNav
    : state.navigations.showExecutedNav,
  (state, _, routeType) => routeType === 'planned'
    ? state.routes.showPlanned
    : state.routes.showExecuted,
  (currentStatus, isAll, isRoute, showNav, showRoute) => {
    if (!showNav || !showRoute ) {
      console.debug('selectNavRerender: false (no-show)', showNav, showRoute);
      return false;
    }
    // A non-shown route
    if (!isRoute && !isAll) {
      console.debug('selectNavRerender: false (no-no)', isRoute, isAll);
      return false;
    }
    // status: LAYER CHANGED
    if (currentStatus === 'layer changed') {
      // if it is the same route: show everything
      if (isRoute) {
        console.debug('selectNavRerender: true (route)', isRoute);
        return true;
      }
      // If it is all routes: show nav
      console.debug('selectNavRerender: true (layer changed-all)', isAll);
      return true;
    }
    // status: MOVING
    if (currentStatus === 'moving') {
      console.debug('selectNavRerender: true (moving)', currentStatus);
      return true;
    }
    // status: SHOW
    if (currentStatus === 'show') {
      console.debug('selectNavRerender: true (show)');
      return true;
    }
    // DEFAULT (should never send this action)
    console.debug('selectNavRerender', currentStatus, isAll, isRoute, "=>", 'no action');
    return true;
  }
);

export const selectTransportRerender = createSelector(
  state => state.transports.showTransports,
  state => state.stops.status,
  state => state.routes.status === 'loading',
  state => state.routes.showExecuted,
  (state, routeId) => selectCheckItIsAll(state, routeId),
  (state, routeId) => state.stops.selectedId === routeId,
  (showTransport, currentStatus, isLoading, showRoute, isAll, isRoute) => {
    if (isLoading || !showTransport || !showRoute) {
      console.debug(
        'selectTransportRerender: false', isLoading, showTransport, showRoute
      );
      return false;
    }
    // A non-shown route
    if (!isRoute && !isAll) {
      console.debug('selectTransportRerender: false (no-no)', isRoute, isAll);
      return false;
    }
    // status: LAYER CHANGED
    if (currentStatus === 'layer changed') {
      // if it is the same route: show everything
      if (isRoute) {
        console.debug('selectTransportRerender: true (route)', isRoute);
        return true;
      }
      // If it is all routes: show nav
      console.debug('selectTransportRerender: true (layer changed-all)', isAll);
      return true;
    }
    // status: MOVING
    if (currentStatus === 'moving') {
      console.debug('selectTransportRerender: true (moving)', currentStatus);
      return true;
    }
    // status: SHOW
    if (currentStatus === 'show') {
      console.debug('selectTransportRerender: true (show)');
      return true;
    }
    // DEFAULT (should never send this action)
    console.debug('selectTransportRerender', currentStatus, isAll, isRoute, "=>", 'no action');
    return false;
  }
)

export const selectStopsRerender = createSelector(
  state => state.stops.status,
  state => state.routes.status === 'loading',
  state => state.routes.status === 'loading' || state.stops.density >= 1,
  (state, routeId) => selectCheckItIsAll(state, routeId),
  (state, routeId) => state.stops.selectedId === routeId,
  (state, routeId, routeType) => routeType === 'planned'
    ? state.stops.showPlannedStops
    : state.stops.showExecutedStops,
  (state, _, routeType) => routeType === 'planned'
    ? state.routes.showPlanned
    : state.routes.showExecuted,
  (
    currentStatus,
    isLoading,
    tooMuchDensity,
    isAll,
    isRoute,
    showStops,
    showRoute
  ) => {
    if (isLoading || showStops === 'never'|| !showRoute) {
      console.debug(
        'selectStopsRerender: false (no-show)', isLoading, showStops, showRoute
      );
      return false;
    }
    // A non-shown route
    if (!isRoute && !isAll) {
      console.debug('selectStopsRerender: false (no-no)', isRoute, isAll);
      return false;
    }
    // status: LAYER CHANGED
    if (currentStatus === 'layer changed') {
      // if it is the same route: show everything
      if (isRoute) {
        if (!tooMuchDensity || showStops === 'always') {
          console.debug('selectStopsRerender: true (r-no)', isRoute, tooMuchDensity);
          return true;
        }
        console.debug('selectStopsRerender: false (r-yes)', isRoute, tooMuchDensity);
        return false;
      }
      // If it is all routes: hide stops
      console.debug('selectStopsRerender: false (layer changed-all)', isAll);
      return false;
    }
    // status: MOVING
    if (currentStatus === 'moving' && showStops !== 'always') {
      console.debug('selectStopsRerender: false (moving)', currentStatus);
      return false;
    }
    // status: SHOW
    if (currentStatus === 'show') {
      if (tooMuchDensity && showStops !== 'always') {
        console.debug('selectStopsRerender: false (show-d)', tooMuchDensity);
        return false;
      }
      console.debug('selectStopsRerender: true (show-!d)', tooMuchDensity);
      return true;
    }
    // DEFAULT (should never send this action)
    console.debug('selectStopsRerender', currentStatus, tooMuchDensity, isAll, isRoute, "=>", 'no action');
    return true;
  }
);

export const selectAvailableShifts = createSelector (
  state => selectRoutesByJourneyAndDepot(
    state, state.journeys.selectedJourney, state.depots.selectedDepot
  ),
  (routes) => {
    return [...new Set(routes.map(route => route.shift))];
  }
);
