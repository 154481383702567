import { useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { Panel, PanelGroup } from "react-resizable-panels";

import { selectUser } from '../features/users/usersSlice';

import LoadingOverlay from '../components/LoadingOverlay';
import InfoMessage from '../components/InfoMessage';
import ToBeImplemented from '../components/ToBeImplemented';
import ResizeHandle from '../components/ResizeHandle';


const OPM = ({ city, dispatch }) => {
  const rightRef = useRef(null);
  const bottomRef = useRef(null);
  const user = useSelector(selectUser);
  console.log("OPM", user);

  return (
    <>
      <LoadingOverlay />
      <InfoMessage />
      <PanelGroup direction="horizontal">
        <Panel
          className="data-panel"
          defaultSize={85}
          minSize={25}
          order={1}
          sx={{position: 'relative'}}
        >
          <PanelGroup direction="vertical">
            <Panel
              className="data-panel"
              defaultSize={25}
              minSize={25}
              order={1}
            >
              <ToBeImplemented />
            </Panel>
            <ResizeHandle
              id="horizontal-handle"
              direction="horizontal"
              type="internal"
              show={true}
            />
            <Panel
              className="data-panel"
              ref={bottomRef}
              defaultSize={75}
              minSize={15}
              order={2}
            >
              <ToBeImplemented background="white" />
            </Panel>
          </PanelGroup>
        </Panel>
        <ResizeHandle id="vertical-handle" direction="vertical" show={true} />
        <Panel
          className="data-panel"
          ref={rightRef}
          defaultSize={15}
          minSize={15}
          order={2}
        >
          <ToBeImplemented background="whitesmoke"/>
        </Panel>
      </PanelGroup>
    </>
  );
}

export default connect()(OPM);
