import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { authStore, authPersistor } from './app/store';

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import SenatorLogin from './screens/Login';

import cities from './config/cities';

import { SenatorAPI } from './services/SenatorAPI';

const Loading = () => {
  return (
    <Backdrop open={true} invisible >
      <CircularProgress />
    </Backdrop>
  )
};

const NoCityRouting = () => {
  console.log("NoCity Initialize SenatorAPI");
  SenatorAPI.initialize(authStore, cities[0].origin);
  
  return (<SenatorLogin cities={cities} />);
}

const NoCity = () => {
  console.log("NoCity loading state...");

  return (
    <Provider store={authStore} >
      <PersistGate persistor={authPersistor} >
        {bootstrapped => bootstrapped
          ? <NoCityRouting />
          : <Loading />
        }
      </PersistGate>
    </Provider>
  );
}

export default NoCity;
