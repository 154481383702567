import L from 'leaflet';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Marker, Popup, useMap, LayerGroup } from 'react-leaflet';
import { selectRouteById } from '../routes/routesSlice';
import {
  selectPlannedNavigationById,
  selectExecutedNavigationById
} from '../routes/navigationsSlice';
import { selectTransportRerender } from '../routes/stopsSlice';
import {
  selectRouteTransport,
  selectTransportCurrentLoad
} from './transportsSlice';

export const TransportMarker = ({ routeId }) => {
  const transport = useSelector(state => selectRouteTransport(state, routeId));
  const transportId = transport ? transport.id : null;
  const [icon, setIcon] = useState(null);
  const currentRoute = useSelector(state => {
    return selectRouteById(state, transport ? transport.currentRoute : null);
  });
  if (currentRoute && currentRoute.id !== routeId) console.error("Route IDs do not match", routeId, currentRoute.id); // TODO sanity check
  const currentRoutePlannedNav = useSelector(state => {
    return selectPlannedNavigationById(state, routeId)
  });
  const currentRouteExecutedNav = useSelector(state => {
    return selectExecutedNavigationById(state, routeId)
  });
  const currentLoad = useSelector(state => {
    return selectTransportCurrentLoad(state, transportId);
  });
  const maxVolume = transport ? transport.model.maxVolume : 0;
  const transpStyle = useMemo(() => {
    if (currentRoute) {
      return `
        filter: ${currentRoute.color.filter};
        height: 24px !important;
        width: 24px !important;
      `;
    }
    return '';
  }, [currentRoute]);
  const transportIcon = transport ? transport.icon : null;
  const shouldShowTransport = icon && transport && transport.position;

  useEffect(() => {
    if (transportIcon) {
      setIcon(L.divIcon({
        className: 'vehicle-marker',
        iconSize: [24, 24],
        html: `<img
          src="${transportIcon}"
          class="van-marker"
          style="${transpStyle}"
        >`
      }));
    }
  }, [transportIcon, transpStyle]);

  return shouldShowTransport && transport.position && 
    <Marker position={transport.position} icon={icon} >
      <Popup>
        <div className='vehicle-popup'>
         <h3>{transport.name}</h3>
        </div>
        <div className="vehicle-popup-info">
          <span className="col vehicle-popup-data-title"><b>Model</b>:</span>
          <span className="col vehicle-popup-data">
            {transport.model.name}
          </span>
        </div>
        <div className="vehicle-popup-info">
          <span className="col vehicle-popup-data-title"><b>Type</b>:</span>
          <span className="col vehicle-popup-data">
            {transport.model.type}
          </span>
        </div>
        <div className="vehicle-popup-info">
          <span className="col vehicle-popup-data-title">
            <b>Propulsion</b>:
          </span>
          <span className="col vehicle-popup-data">
            {transport.model.drivingForce}
          </span>
        </div>
        <br />
        <div className="vehicle-popup-info">
          <span className="col vehicle-popup-data-title">
            <b>Max capacity</b>:
          </span>
          <span className="col vehicle-popup-data">
            {`${maxVolume.toFixed(3)} m³`}
          </span>
        </div>
        <div className="vehicle-popup-info">
          <span className="col vehicle-popup-data-title">
            <b>Current load</b>:
          </span>
          <span className="col vehicle-popup-data">
            {`${currentLoad.toFixed(3)} m³
               (${(currentLoad/maxVolume * 100).toFixed(2)}%`})
          </span>
        </div>
        <br />
        <div className="vehicle-popup-info">
          <span className="col vehicle-popup-data-title">
            <b>Covered distance</b>:
          </span>
          <span className="col vehicle-popup-data">
            {`${currentRouteExecutedNav
                ? currentRouteExecutedNav.distance.toFixed(2)
                : '0.00'
              } km`
            }
            <i>{`(Est: ${currentRoutePlannedNav.distance.toFixed(2)})`}</i>
          </span>
        </div>
      </Popup>
    </Marker>;
}

const TransportRerender = ({ transportRef, routeId }) => {
  const map = useMap();
  const shouldRender = useSelector(state => selectTransportRerender(state, routeId));

  if (shouldRender) {
    // Show transport 
    console.log(`Route ${routeId} -> show transport`);
    if (transportRef.current && transportRef.current._map == null) {
      map.addLayer(transportRef.current);
    }
  } else {
    // Hide transport
    console.log(`Route ${routeId} -> hide transport`);
    if (transportRef.current && transportRef.current._map) {
      map.removeLayer(transportRef.current);
    }
  }

  return null;
}

export const Transport = ({ routeId }) => {
  const transportRef = useRef();

  return  (
    <LayerGroup ref={transportRef} >
      <TransportMarker routeId={routeId} />
      <TransportRerender transportRef={transportRef} routeId={routeId} />
    </LayerGroup>
  );
};
