import Popover from '@mui/material/Popover';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';

import ListTiles from './ListTiles';
import ListExtraLayers from './ListExtraLayers';
import LayersBottomNavigation from './LayersBottomNavigation';


const MenuPopover = ({ open, anchorEl, handleClose }) => {
  const [value, setValue] = useState(0);

  return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Paper elevation={2} sx={{ minWidth: '250px' }} >
          <List
            dense
            sx={{ width: '100%' }}
          >
            { value === 0 && <ListExtraLayers />}
            { value === 1 && <ListTiles />}
          </List>
          <LayersBottomNavigation navValue={value} setNavValue={setValue} />
        </Paper>
      </Popover>
  );
}

export default MenuPopover;
