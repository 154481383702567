import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import AllCombos from './AllCombos';
import SelectedCombo from './SelectedCombo';

import SRMOptions from '../SRMOptions';

const TransparentTopBar = ({ mediaQueries, showKPIs, showTable }) => {
  const [isOptionOpened, setOptionsOpened] = useState(false);
  const {
    isXSScreen,
    isXXSScreen,
    showAllCombos
  } = mediaQueries.bar;
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const showOptionsFullscreen = useMediaQuery('(max-height:580px)');
  console.log("TransparentTopBar", isSmallScreen, showAllCombos);

  const openOptions = () => setOptionsOpened(true);
  const closeOptions = () => setOptionsOpened(false);

  return (
    <>
    <Slide direction="down" in={!isXXSScreen} >
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      useFlexGap
      sx={{
        background: 'transparent',
        pt: 1,
        pr: 2,
        pl: isSmallScreen ? 9 : 2,
        position: "absolute",
        zIndex: 9999,
        width: "100%",
      }}
    >
      {showAllCombos
        ? <AllCombos />
        : <SelectedCombo
            settings={{ click: openOptions, shown: isOptionOpened }}
            mediaQueries={mediaQueries.combos}
            showTable={showTable}
            showKPIs={showKPIs}
          />
      }
      <Slide direction="down" in={!isXSScreen} unmountOnExit>
      <Stack direction="row" spacing={2} alignItems="center" >
        <Paper sx={{ borderRadius: "50%" }} elevation={2}>
          <IconButton size="large" onClick={openOptions}>
            <SettingsOutlinedIcon color={isOptionOpened ? "primary" : ""}/>
          </IconButton>
        </Paper>
        <Paper sx={{ borderRadius: "50%" }} elevation={2}>
          <IconButton size="large" onClick={showTable.click} >
            <TableChartOutlinedIcon color={showTable.shown ? "primary" : ""} />
          </IconButton>
        </Paper>
        <Paper sx={{ borderRadius: "50%" }} elevation={2}>
          <IconButton size="large" onClick={showKPIs.click}>
            <LeaderboardOutlinedIcon color={showKPIs.shown ? "primary" : ""} />
          </IconButton>
        </Paper>
      </Stack>
      </Slide>
    </Stack>
    </Slide>
    <SRMOptions
      open={isOptionOpened}
      handleClose={closeOptions}
      fullscreen={showOptionsFullscreen}
    />
    </>
  );
}

export default TransparentTopBar;
