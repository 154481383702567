import { Fragment, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  useMap,
  GeoJSON,
  LayerGroup,
  FeatureGroup,
} from 'react-leaflet';
import { RoutePoint } from '../routePoints/RoutePoint';
import { Transport } from '../transports/Transport';
import { selectRouteById } from './routesSlice';
import { selectNavigationByTypeAndId } from './navigationsSlice';
import {
  selectNavRerender,
  selectStopsRerender,
  selectStopsByTypeAndId
} from './stopsSlice';

const plannedStyle = {
  weight: 5,
  opacity: 0.3,
  zIndex: 1
};
const executedStyle = {
  weight: 2,
  opacity: 1,
  zIndex: 30
}

export const Route = ({ routeId }) => {
  const groupRef = useRef();
  const route = useSelector(state => selectRouteById(state, routeId));
  const isLoaded = true;
  const color = route.color.color;
  console.log('Route', routeId);

  return (
    <FeatureGroup ref={groupRef}>
      <RouteNavig
        key='planned-nav'
        routeId={routeId}
        routeType='planned'
        color={color}
      />
      {isLoaded && <Fragment key='executed-nav-transport'>
        <RouteNavig key='executed-nav'
          routeId={routeId}
          routeType='executed'
          color={color}
        />
        <Transport key="transport" routeId={routeId} />
      </Fragment>}
      {isLoaded && <RouteStops
        key='planned-stops'
        routeId={routeId}
        routeType='planned'
        color={route.color.color}
      />}
      {isLoaded && <RouteStops
        key='executed-stops'
        routeId={routeId}
        routeType='executed'
        color={route.color.color}
      />}
    </FeatureGroup>
  );
}

const RouteNavigRerender = ({ navRef, routeId, routeType }) => {
  const map = useMap();
  const shouldRender = useSelector(state => {
    return selectNavRerender(state, routeId, routeType);
  });

  if (shouldRender) {
    // Show navigation
    console.log(`Route ${routeId} -> show navigation`);
    if (navRef.current && navRef.current._map == null) {
      map.addLayer(navRef.current);
    }
  } else {
    // Hide navigation
    console.log(`Route ${routeId} -> hide navigation`);
    if (navRef.current && navRef.current._map) {
      map.removeLayer(navRef.current);
    }
  }

  return null;
}

const RouteNavigGeojson = ({ routeId, routeType, color }) => {
  const navigation = useSelector(
    state => selectNavigationByTypeAndId(state, routeType, routeId)
  );
  const shouldShow = navigation && navigation.navigation.features.length > 0;
  const style = routeType === 'planned' ? plannedStyle : executedStyle;

  return shouldShow && (
    <GeoJSON
      key={navigation.key}
      data={navigation.navigation}
      pathOptions={{ color: color }}
      style={style}
    />
  );
}

const RouteNavig = ({ routeId, routeType, color }) => {
  const navRef = useRef();
  const [isNavLoaded, setNavLoaded] = useState(false); 

  useEffect(() => {
    setTimeout(() => setNavLoaded(true), 100);
  }, []);

  return (
    <LayerGroup ref={navRef} >
      <RouteNavigGeojson
        routeId={routeId}
        routeType={routeType}
        color={color}
      />
      {isNavLoaded && <RouteNavigRerender
        navRef={navRef}
        routeId={routeId}
        routeType={routeType}
      />}
    </LayerGroup>
  );
}

const RouteStopsRerender = ({ stopsRef, routeId, routeType }) => {
  const map = useMap();
  const shouldRender = useSelector(state => {
    return selectStopsRerender(state, routeId, routeType);
  });

  if (shouldRender) {
    // show stops
    console.log(`Route ${routeId} -> show stops`);
    if (stopsRef.current && stopsRef.current._map == null) {
      map.addLayer(stopsRef.current);
    }
  } else {
    // Hide stops
    console.log(`Route ${routeId} -> hide stops`);
    if (stopsRef.current && stopsRef.current._map) {
      map.removeLayer(stopsRef.current);
    }
  }

  return null;
}


const RouteStopsMarkers = ({ routeId, routeType, color, setLoaded }) => {
  const stopsIds = useSelector(
    state => selectStopsByTypeAndId(state, routeType, routeId)
  );

  if (stopsIds) {
  const result = stopsIds.stops.map(stopId => (
     <RoutePoint
        key={stopId}
        routePointId={stopId}
        pointColor={color}
      />
  ));
    setTimeout(() => setLoaded(true), 10);
    return result;
  }
  return null;
}

const RouteStops = ({ routeId, routeType, color, density, type }) => {
  const stopsRef = useRef();
  const [areMarkersLoaded, setMarkersLoaded] = useState(false); 

  return (
    <LayerGroup ref={stopsRef} >
      <RouteStopsMarkers
        routeId={routeId}
        routeType={routeType}
        color={color}
        setLoaded={setMarkersLoaded}
      />
      {areMarkersLoaded && <RouteStopsRerender
        stopsRef={stopsRef}
        routeId={routeId}
        routeType={routeType}
      />}
    </LayerGroup>
  );
}
