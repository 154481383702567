import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { Alert, AlertTitle, Snackbar } from '@mui/material';

import { selectInfoMessage } from '../features/routes/routesSlice';

const InfoMessage = () => {
  const infoMessage = useSelector(selectInfoMessage);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect (() => {
    setOpenAlert(true);
  }, [infoMessage])

  return infoMessage.message !== "" && (
    <Snackbar
      open={openAlert}
      autoHideDuration={3500}
      onClose={() => {setOpenAlert(false);}}
      sx={{ minWidth: '25%' }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        variant="outlined"
        severity="info"
        sx={{ width: '100%', bgcolor: '#e5f6fd' }}
        onClose={() => {setOpenAlert(false);}}
      >
        <AlertTitle>{infoMessage.title}</AlertTitle>
        {infoMessage.message}
      </Alert>
    </Snackbar>
  );
}

export default InfoMessage;
