import { useState } from 'react';

import LayersIcon from './LayersIcon';
import TileLayers from './TileLayers';
import ExtraLayers from './ExtraLayers';
import MenuPopover from './MenuPopover';

const LayersMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  console.log("LayersMenu", anchorEl, open);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log("LayersMenu handleOpen");
  };

  const handleClose = () => {
    setAnchorEl(null);
    console.log("LayersMenu handleClose");
  };

  
  return (
    <>
      <LayersIcon showIcon={!open} handleClick={handleClick} />
      <MenuPopover open={open} anchorEl={anchorEl} handleClose={handleClose} />
      <TileLayers />
      <ExtraLayers />
    </>
  );
};

export default LayersMenu;
