import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import WarehouseIcon from '@mui/icons-material/Warehouse';

import {
  selectAvailableDepots,
  setSelectedDepot,
  selectSelectedDepot
} from '../../features/depots/depotsSlice';

const ComboDepots = ({ isGrouped, radius, dispatch }) => {
  const depots = useSelector(selectAvailableDepots);
  const selectedDepot = useSelector(selectSelectedDepot);
  const [inputValue, setInputValue] = useState('');
  console.log("ComboDepots", depots, selectedDepot);

  useEffect(() => {
    if (depots.length === 1) {
      dispatch(setSelectedDepot({ depot: depots[0].id }));
      console.log("ComboDepots -> dispatch (1)", depots[0].id);
    } else if (depots.length > 1 && selectedDepot.id !== 'All') {
      if (depots.filter(d => d.id === selectedDepot.id) === 0) {
        dispatch(setSelectedDepot({ depot: 'All' }));
        console.log("ComboDepots -> dispatch (2) All");
      }
    }
  }, [depots, selectedDepot, dispatch]);

  return (
    <Autocomplete
      id="combo-box-depots"
      disableClearable={true}
      options={depots}
      fullWidth
      sx={{
        // width: isGrouped ? 100 : 250,
        height: 48,
        "& .MuiAutocomplete-popupIndicator": { transform: "none" }
      }}
      getOptionLabel={(depot) => depot.name}
      popupIcon={
        <WarehouseIcon />
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={isGrouped ? "" : "Depot"}
          variant="outlined"
          error={depots.length === 0}
          sx={{
            "& .MuiFilledInput-root": {
              background: "white",
              height: '48px',
              borderRadius: isGrouped ? 0 : radius,
              alignItems: isGrouped ? "center" : "flex-end"
            },
            "& .MuiAutocomplete-inputRoot": {
              borderTopRightRadius: radius,
              borderBottomRightRadius: radius,
              borderTopLeftRadius: isGrouped ? 0 : radius,
              borderBottomLeftRadius: isGrouped ? 0 : radius,
              padding: '5px',
            },
            "& .MuiInputLabel-root": {
              transform: 'translate(18px, 4px) scale(0.75)'
            },
            "& .MuiOutlinedInput-notchedOutline>legend>span": {
              display: isGrouped ? "inline-block" : "none"
            },
            "& .MuiOutlinedInput-root": {
              paddingTop: "5px !important",
              paddingBottom: "5px !important"
            },
            "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
              paddingLeft: isGrouped ? "5px" : "13px",
              paddingBottom: isGrouped ? "7.5px" : "0px !important",
              paddingTop: isGrouped ? "7.5px" : "15px !important"
            }
          }}
        />
      )}
      value={selectedDepot}
      onChange={(event, newValue) => {
        console.log("ComboDepots onChange", newValue);
        dispatch(setSelectedDepot({ depot: newValue.id }));
        console.log("ComboDepots -> dispatch (3)", newValue.id);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        console.log("ComboDepots onInputChange", newInputValue);
        setInputValue(newInputValue);
      }}
    />
  );
}

export default connect()(ComboDepots);
