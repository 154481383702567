import { connect, useSelector } from 'react-redux';

import { selectUser } from '../features/users/usersSlice';

import LoadingOverlay from '../components/LoadingOverlay';
import InfoMessage from '../components/InfoMessage';
import ToBeImplemented from '../components/ToBeImplemented';

const Alerts = ({ city, dispatch }) => {
  const user = useSelector(selectUser);
  console.log("Alerts LoadStatus", user);

  return (
    <>
      <LoadingOverlay />
      <InfoMessage />
      <ToBeImplemented />
    </>
  );
}

export default connect()(Alerts);
