import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import { authCombinedReducer, persistReducers } from './reducers';

import cities from '../config/cities';

export const authStore = configureStore({
  reducer: authCombinedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});
export const authPersistor = persistStore(authStore);

export const stores = Object.fromEntries(cities.map(city => [
 city.title,
 configureStore({
    reducer: persistReducers[city.title],
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
 })
]));

export const persistors = Object.fromEntries(cities.map(city => [
  city.title,
  persistStore(stores[city.title])
]));
