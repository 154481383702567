import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';

const LayersBottomNavigation = ({ navValue, setNavValue }) => {
  return (
    <Paper elevation={3} >
      <BottomNavigation
        showLabels
        value={navValue}
        onChange={(event, newValue) => {
          setNavValue(newValue);
        }}
      >
        <BottomNavigationAction label="layers" icon={<LayersOutlinedIcon />} />
        <BottomNavigationAction label="tiles" icon={<PublicOutlinedIcon />} />
      </BottomNavigation>
    </Paper>
  );
};

export default LayersBottomNavigation;
