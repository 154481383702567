import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import RouteIcon from '@mui/icons-material/Route';

import {
  selectSelectedRoute,
  selectAvailableRoutes
} from '../../features/routes/routesSlice';
import { changeLayers } from '../../features/routes/stopsSlice';

const ComboRoutes = ({ isGrouped, radius, dispatch }) => {
  const routes = useSelector(selectAvailableRoutes);
  const selectedRoute = useSelector(selectSelectedRoute);
  const [inputValue, setInputValue] = useState('');
  console.log("ComboRoutes", routes, selectedRoute);

  useEffect(() => {
    if (routes.length === 1) {
      dispatch(changeLayers({ routeId: routes[0].id }));
      console.log("ComboRoutes -> dispatch (1)", routes[0].id);
    } else if (routes.length > 1 && selectedRoute.id !== 'All') {
      if (routes.filter(r => r.id === selectedRoute.id).length === 0) {
        console.log("ComboRoutes -> dispatch (2) All")
        dispatch(changeLayers({ routeId: 'All' }));
      }
    }
  }, [routes, selectedRoute, dispatch]);

  return (
      <Autocomplete
        id="combo-box-routes"
        disableClearable={true}
        options={routes}
        fullWidth
        sx={{
          height: 48,
          "& .MuiAutocomplete-popupIndicator": { transform: "none" }
        }}
        getOptionLabel={(route) => route.name}
        renderOption={(props, route) => (
          <Box component="li" sx={{}} {...props}>
            <span className="color-box" style={{ background: route.color.color}}>
            </span>
            {route.name}
          </Box>
        )}
        popupIcon={
          <RouteIcon />
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={isGrouped ? "" : "Route"}
            variant="outlined"
            error={routes.length === 0}
            sx={{
              "& .MuiOutlinedInput-root": {
                background: "white",
                height: '48px',
                borderRadius: isGrouped ? 0 : radius,
                alignItems: isGrouped ? "center" : "flex-end"
              },
              "& .MuiAutocomplete-inputRoot": {
                borderTopRightRadius: radius,
                borderBottomRightRadius: radius,
                borderTopLeftRadius: isGrouped ? 0 : radius,
                borderBottomLeftRadius: isGrouped ? 0 : radius,
                padding: '5px',
              },
              "& .MuiInputLabel-root": {
                transform: 'translate(18px, 4px) scale(0.75)'
              },
              "& .MuiOutlinedInput-notchedOutline>legend>span": {
                display: isGrouped ? "inline-block" : "none"
              },
              "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
                paddingTop: "5px",
                paddingRight: "4px",
                paddingBottom: isGrouped ? "2px" : "0px",
                paddingLeft: isGrouped ? "5px" : "13px",
              }
            }}
          />
        )}
        value={selectedRoute}
        onChange={(event, newValue) => {
          console.log("ComboRoutes onChange", newValue);
          dispatch(changeLayers({ routeId: newValue.id }));
          console.log("ComboRoutes -> dispatch (3)", newValue.id);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          console.log("ComboRoutes onInputChange", newInputValue);
          setInputValue(newInputValue);
        }}
      />
  );
}

export default connect()(ComboRoutes);
