import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit'

const shipmentsAdapter = createEntityAdapter();
const initialState = shipmentsAdapter.getInitialState();

const shipmentsSlice = createSlice({
  name: 'shipments',
  initialState,
  reducers: {
    createShipment: shipmentsAdapter.addOne,
    createShipments: shipmentsAdapter.addMany
  }
});

export const {
  createShipment,
  createShipments
} = shipmentsSlice.actions;

export default shipmentsSlice.reducer;

export const {
  selectAll: selectAllshipments,
  selectById: selectShipmentById,
  selectIds: selectShipmentIds,
  selectTotal: selectTotalshipments
} = shipmentsAdapter.getSelectors(state => state.routes);
