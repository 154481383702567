import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit'
import { SenatorAPI } from '../../services/SenatorAPI';

const restrictionsAdapter = createEntityAdapter();
const initialState = restrictionsAdapter.getInitialState({
  showRestrictions: false
});

export const loadRestrictions = createAsyncThunk(
  'restrictions/loadRestrictions',
  async (_, thunkAPI) => {
    try {
      const restrictions = await SenatorAPI.fetchRestrictions();
      thunkAPI.dispatch(restrictionsLoaded(restrictions));
    } catch (err) {
      console.error("loadRestrictions", err);
    }
  }
);

const restrictionsSlice = createSlice({
  name: 'restrictions',
  initialState,
  reducers: {
    restrictionsLoaded: restrictionsAdapter.addMany,
    setShowRestrictions (state, action) {
      state.showRestrictions = action.payload;
    }
  }
});

export default restrictionsSlice.reducer;

export const {
 restrictionsLoaded,
 setShowRestrictions
} = restrictionsSlice.actions;

export const {
  selectAll: selectAllRestrictions,
  selectEntities: selectRestrictionsDict,
  selectById: selectRestrictionById,
  selectIds: selectRestrictionIds,
  selectTotal: selectLoadedRestrictions
} = restrictionsAdapter.getSelectors(state => state.restrictions);

export const selectShowRestrictions = state => {
  return state.restrictions.showRestrictions
};
