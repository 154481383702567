import * as L from 'leaflet';
import '../../utils/IconMaterial';
import { useEffect, useState } from 'react';
import { Marker } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { DepotPopup } from './DepotPopup';
import { selectDepotById } from './depotsSlice';

export const Depot = ({ depotId }) => {
  const depot = useSelector(state => selectDepotById(state, depotId));
  const [icon, setIcon] = useState(null);
  const position = [depot.lat, depot.lng];
  console.log("Depot position", position, depot);

  useEffect(() => {
    setIcon(L.IconMaterial.icon({
      icon: 'warehouse',
      iconColor: 'white',
      // iconColor: '#ff004f',
      markerColor: 'rgba(255,255,255,0.85)',
      // markerColor: '#ff004f',
      outlineColor: 'black',
      //outlineColor: 'white',
      // outlineColor: '#ff004f',
      outlineWidth: 2,
      iconSize: [31, 42]
    }));
  }, []);

  return icon &&  (
    <Marker position={position} icon={icon} >
      <DepotPopup depot={depot} />
    </Marker>
  );
};
