import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import {
  Box,
  Link,
  Alert,
  Paper,
  Avatar,
  Select,
  MenuItem,
  Container,
  TextField,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  useMediaQuery,
  InputAdornment,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Login,
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import {
  setCity,
  selectCity,
  createUser,
  selectToken
} from '../features/users/usersSlice';
import { SenatorAPI } from '../services/SenatorAPI';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://deusto.es/">
        University of Deusto
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const SenatorLogin = ({ cities, dispatch }) => {
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const currentCity = useSelector(selectCity);
  const [selectedCity, setSelectedCity] = useState(cities.length - 1); // TODO set to ''
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);  // TODO set to true
  const [isLogging, setIsLogging] = useState(false);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const alertVisibility = errorMessage ? 'shown' : 'hidden';
  console.log("Login", cities, token, selectedCity, btnDisabled, isLogging, errorMessage, alertVisibility, currentCity);

  const handleSelectCityChange = (e) => {
    const cityIndex = e.target.value;
    const city = cities[cityIndex];
    setSelectedCity(cityIndex);
    SenatorAPI.origin = city.origin;
    dispatch(setCity({ city: city }));
    console.log("Login setCity", city);
  }

  const handleSubmit = async (event) => {
    setIsLogging(true);
    console.log("Login submit!");
    event.preventDefault();
    let logged = false;
    const data = new FormData(event.currentTarget);
    const user = {
      email: data.get('email'),
      password: data.get('password'),
      city: data.get('city')
    };
    console.log("Login user data", user);
    user.city = user.city ? cities[user.city] : cities[0];
    console.log("Login: logging...", user);
    try {
      logged = await SenatorAPI.login(
        user.email, user.password, user.city.origin
      );
      if (!logged) {
        setErrorMessage("Email or password are incorrect");
      }
      console.log("Login logged?", logged);
    } catch (err) {
      console.log("Login", err);
      if (err.response) {
        switch (err.response.status) {
          case 400: setErrorMessage("The email format is incorrect"); break;
          case 401:
          case 403: setErrorMessage("User or password are incorrect"); break;
          default: setErrorMessage("Something wrong happened"); break;
        }
      } else {
        let msg = "Something wrong happened";
        if (err.message) {
          msg += ` (${err.message})`;
        }
        setErrorMessage(msg);
      }
    }
    if (!logged) {
      setIsLogging(false);
    } else {
      setTimeout(() => setIsLogging(false), 1750);
    }
  };

  useEffect(() => {
    const performLogin = async () => {
        try {
          console.log("Login: logged.");
          const userInfo = await SenatorAPI.getUserInfo();
          dispatch(createUser(userInfo));
          console.log("Login: Navigate to SRM");
          console.log(currentCity);
          navigate(`/${currentCity.name}`);
        } catch (err) {
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 401: setErrorMessage("The autenthication token is invalid"); break;
              case 403:
                setErrorMessage("User is not allowed to access the platform.");
                break;
              default: setErrorMessage("Something wrong happened"); break;
            }
          } else {
            let msg = "Something wrong happened";
            if (err.message) {
              msg += ` (${err.message})`;
            }
            setErrorMessage(msg);
          }
        }
    };

    if (token) {
      performLogin();
    }
  }, [token, currentCity, dispatch, navigate]);

  const handleButton = (e) => {
    const data = new FormData(e.currentTarget);
    if (data.get('email') === '') {
      setBtnDisabled(true);
    } else if (data.get('password') === '') {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
    setErrorMessage("");
  }

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: "center"
      }}
    >
      <Paper variant="outlined" sx={{ marginBottom: 1, padding: 5 }} >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar
            srcSet="/senator.svg"
            variant="rounded"
            alt="Senator logo"
            sx={{ m: 1, width: 90, height: 90, border: '1px filled black' }}
            imgProps={{ sx: { objectFit: 'fill'} }}
          />
          <Typography component="h1" variant="h3" sx={{textTransform: 'capitalize'}}>
            Senator
          </Typography>
          <Typography variant="subtitle1">
            Use your SENATOR identity
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 6 }} onChange={handleButton} >
            <TextField
              margin="normal"
              fullWidth
              id="user"
              label="Email"
              name="email"
              autoComplete="username"
              autoFocus
              disabled={isLogging}
              defaultValue={"admin@senatorproject.eu" /*TODO remove*/}
            />
            <FormControl
              sx={{ mt: 1 }}
              variant="outlined"
              fullWidth
              disabled={isLogging}
            >
              <InputLabel htmlFor="password">
                Password
              </InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                defaultValue={"Y3Cx$1HyJ6jbMaq4" /*TODO remove*/}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                      disabled={isLogging}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            {cities.length > 1 && <FormControl
              sx={{ mt: 2 }}
              variant="outlined"
              fullWidth
              disabled={isLogging}
            >
              <InputLabel id="cityLabel" htmlFor="city">
                City
              </InputLabel>
              <Select
                id="city"
                name="city"
                labelId="cityLabel"
                value={selectedCity}
                label="City"
                onChange={handleSelectCityChange}
              >
                {cities.map((city, index) => (
                  <MenuItem key={city.name} value={index}>{city.title}</MenuItem>
                ))}
              </Select>
            </FormControl>}
            <Alert
              severity="error"
              sx={{
                mt: 2,
                visibility: alertVisibility
              }}
            >
              {errorMessage}
            </Alert>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                endIcon={<Login />}
                size="large"
                loading={isLogging}
                loadingPosition="end"
                disabled={btnDisabled}
                fullWidth={isSmallScreen}
              >
                LOG IN
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Paper>
      <Copyright />
    </Container>
  );
}

export default connect()(SenatorLogin);
