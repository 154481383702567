import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { MapContainer, Pane } from 'react-leaflet';
import { setMapPixelSize, setMapBounds } from '../features/routes/stopsSlice';
import { normalizeBounds } from '../utils';
import LayersMenu from './LayersMenu';

const MapComponent = ({ position, zoomLevel, children }) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const dispatch = useDispatch();

  const handleLoad = (e) => {
    const map = e.target;
    const size = map.getSize();
    dispatch(setMapPixelSize({ x: size.x, y: size.y }));
    dispatch(setMapBounds({ bounds: normalizeBounds(map.getBounds())}));
    console.log("Calculated event map ready", size);
    setTimeout(() => setMapLoaded(true), 10);
  }

  return (
    <MapContainer
      center={position}
      zoom={zoomLevel}
      zoomControl={false}
      whenReady={handleLoad}
    >
      <Pane name="ULL" style={{ zIndex: 201 }} />
      <Pane name="Restrictions" style={{ zIndex: 202 }} />
      <Pane name="LoadingAreas" style={{ zIndex: 203 }} />
      {mapLoaded && children}
      <LayersMenu />
    </MapContainer>
  );
};

export default MapComponent;
