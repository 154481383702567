import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit'
import { selectRoutesByJourney } from '../routes/routesSlice';
import { SenatorAPI } from '../../services/SenatorAPI';

const depotsAdapter = createEntityAdapter();
const allDepotsSpecialDepot = { id: 'All', name: 'All' };

const initialState = depotsAdapter.getInitialState({
  selectedDepot: 'All',
  showDepots: false
});

export const loadDepots = createAsyncThunk(
  'depots/loadDepots',
  async (_, thunkAPI) => {
    try {
      const depots = await SenatorAPI.fetchDepots();
      thunkAPI.dispatch(depotsLoaded(depots));
    } catch (err) {
      console.error("loadDepots", err);
    }
  }
);

const depotsSlice = createSlice({
  name: 'depots',
  initialState,
  reducers: {
    depotsLoaded: depotsAdapter.addMany,
    setSelectedDepot (state, action) {
      state.selectedDepot = action.payload.depot;
    },
    setShowDepots (state, action) {
      state.showDepots = action.payload;
    }
  }
});

export const {
 depotsLoaded,
 setShowDepots,
 setSelectedDepot
} = depotsSlice.actions;

export default depotsSlice.reducer;

export const {
  selectAll: selectAllDepots,
  selectEntities: selectDepotsDict,
  selectById: selectDepotById,
  selectIds: selectDepotIds,
  selectTotal: selectLoadedDepots
} = depotsAdapter.getSelectors(state => state.depots);


export const selectShowDepots = state => state.depots.showDepots;

export const selectSelectedDepot = createSelector (
  selectDepotsDict,
  state => state.depots.selectedDepot,
  (depotsDict, depotId) => {
    if (depotId !== 'All') {
      return depotsDict[depotId];
    }
    return allDepotsSpecialDepot;
  }
);

export const selectAvailableDepots = createSelector (
  selectDepotsDict,
  state => selectRoutesByJourney(state, state.journeys.selectedJourney),
  (depotsDict, routes) => {
    const depotIds = [...new Set(routes.map(route => route.depot))];
    const depots = depotIds.map(depotId => depotsDict[depotId]);
    // Sort by name
    depots.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    // If there are more than one depot, return the "All" depot
    if (depots.length > 1) {
      return [allDepotsSpecialDepot, ...depots];
    }
    return depots;
  }
);
