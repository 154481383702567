import jwt_decode from 'jwt-decode';
import axios from 'axios';
import Bottleneck from 'bottleneck';
import { setErrorMessage } from '../features/routes/routesSlice';
import { setToken } from '../features/users/usersSlice';

// const OAuthURL = 'https://azam-t-correosid-a0-svc-netcore-correosidservices-01.azurewebsites.net/Api';
const OAuthURL = 'https://senatorid.senator.apps.deustotech.eu/Api';

export class SenatorAPI {
  static origin = '';
  static store = null;
  static token = null;
  static isLogging = false;
  static refreshTokenId = null;
  static limiter = new Bottleneck({ maxConcurrent: 10 });

  static async initialize (store, origin) {
    this.origin = origin;
    this.store = store;
    const state = this.store.getState();
    const token = state.users.token;
    console.log("SenatorAPI initialize", token);
    if (token && this.token !== token) {
      this.token = token;
      const decoded = jwt_decode(token.idToken);
      console.log("SenatorAPI decoded token", decoded);
      const diff = decoded.exp * 1000 - new Date() - 60000;
      if (diff > 0) {
        this.refreshTokenId = setTimeout(
          () => { this.refreshToken(); },
          diff
        );
      } else {
        this.refreshToken();
      }
      console.log("SenatorAPI token initialized: ", this.token);
    } else if (token == null && this.token) {
      this.store.dispatch(setToken({ token: this.token }));
    }
  }

  static async login (username, password, origin) {
    // Sanity check, if there is a valid token, do not login again
    if (this.token || this.isLogging) {
      return !this.isLogging;
    }
    this.isLogging = true;
    // Do login
    console.log("SenatorAPI Logging into SENATOR platform...");

    try {
      const response = await axios.post(
        `${OAuthURL}/Login`,
        {
          username: username,
          password: password
        },
        {
          headers: {
            'content-type': 'application/json',
            'ApplicationOid': '30a0d5f0-2759-4022-91e6-0f4504cb8a86',
          }
        });

      this.token = response.data;
      console.log("SenatorAPI token", this.token);
      this.refreshTokenId = setTimeout(
        () => { this.refreshToken(); },
        (this.token.expiresIn - 3) * 60 * 1000
      );
      this.store.dispatch(setToken({ token: this.token }));
      console.log("SenatorAPI Logged succesfully", this.token);
    } catch (error) {
      console.error("SenatorAPI Error in login", error);
      this.token = null;
      this.store.dispatch(setToken({ token: this.token }));
      throw error;
    } finally {
      this.isLogging = false;
    }

    this.origin = origin;
    return this.token != null;
  }

  static logout () {
    if (this.token != null) {
      console.log("SenatorAPI logout");
      clearTimeout(this.refreshTokenTimer);
      this.token = null;
      this.store.dispatch(setToken({ token: this.token }));
    }
  }

  static async refreshToken () {
    try {
      if (!this.isLogging) {
        console.log("SenatorAPI Refreshing token...", this.token);
        this.isLogging = true;
        clearTimeout(this.refreshTokenId);
        const response = await axios.post(
          `${OAuthURL}/Login/RefreshToken`,
          {
            idToken: this.token.idToken,
            refreshToken: this.token.refreshToken
          },
          {
            headers: {
              'content-type': 'application/json',
              'ApplicationOid': '30a0d5f0-2759-4022-91e6-0f4504cb8a86'
            }
          });
        this.token = response.data;
        console.log("SenatorAPI Token correctly renewed.", this.token);
        this.refreshTokenId = setTimeout(
          () => { this.refreshToken(); },
          (this.token.expiresIn - 3) * 60 * 1000
        );
        this.store.dispatch(setToken({ token: this.token }));
      }
    } catch (error) {
      console.error("SenatorAPI Error refreshing token", error);
      this.store.dispatch(setErrorMessage({
        title: 'Error refreshing token',
        message: error.message
      }));
      this.token = null;
      this.store.dispatch(setToken({ token: this.token }));
    } finally {
      this.isLogging = false;
    }
  }

  static async fetchAPIDataGeojson(url) {
    console.log('SenatorAPI fetchAPIDataGeojson', url);
    while (this.isLogging) {
      await new Promise((resolve) => { setTimeout(resolve, 100); });
    }
    let numAttempts = 0;
    while (numAttempts++ < 2) {
      try {
        const response = await axios.get(url, {
          headers: {
            accept: 'application/geo+json',
            Authorization: `Bearer ${this.token.idToken}`
          }
        });

        return response.data
      } catch(err) {
        if (err.response && err.response.status) {
          if (err.response.status === 401 && numAttempts < 1) {
            await this.refreshToken();
          }
        }
        console.error("SenatorAPI fetchAPIData - err", err);
        throw err;
      }
    }
  }

  static async fetchAPIData(url) {
    console.log('SenatorAPI fetchAPIData', url);
    while (this.isLogging) {
      await new Promise((resolve) => { setTimeout(resolve, 100); });
    }
    let numAttempts = 0;
    while (numAttempts++ < 2) {
      try {
        const response = await axios.get(url, {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${this.token.idToken}`
          }
        });

        return response.data
      } catch(err) {
        if (err.response && err.response.status) {
          if (err.response.status === 401 && numAttempts < 1) {
            clearTimeout(this.refreshTokenId);
            this.token = null;
            this.store.dispatch(setToken({ token: this.token }));
          }
        }
        console.error("SenatorAPI fetchAPIData - err", err);
        throw err;
      }
    }
  }

  static async fetchNavigation (routeId, routeType) {
    const url = `${this.origin}/srm/route/${routeId}/${routeType}/navigation`;
    return this.limiter.schedule(() => this.fetchAPIDataGeojson(url));
  }

  static async fetchStops (routeId, routeType) {
    const url = `${this.origin}/srm/route/${routeId}/${routeType}/stops`;
    return this.limiter.schedule(() => this.fetchAPIData(url));
  }

  static async fetchRouteInfo (routeId, routeType) {
    const navigation = this.fetchNavigation(routeId, routeType);
    const stops = this.fetchStops(routeId, routeType);
    return await Promise.all([navigation, stops]);
  }

  static async fetchRoutes (journey) {
    const url = `${this.origin}/srm/routes/planned/date/${journey}`;
    return this.limiter.schedule(() => this.fetchAPIData(url));
  }

  static async fetchRoute (routeId) {
    const url = `${this.origin}/srm/route/${routeId}`;
    return this.limiter.schedule(() => this.fetchAPIData(url));
  }

  static async findRouteByTracker (trackerId, journey) {
    const url = `${this.origin}/srm/route/by/tracker/${trackerId}/date/${journey}`;
    return this.limiter.schedule(() => this.fetchAPIData(url));
  }

  static async fetchDepots () {
    const url = `${this.origin}/opm/depots`;
    return this.limiter.schedule(() => this.fetchAPIData(url));
  }

  static async fetchULL () {
    const url = `${this.origin}/dui/ull`;
    return this.limiter.schedule(() => this.fetchAPIData(url));
  }

  static async fetchRestrictions () {
    const url = `${this.origin}/dui/restrictions`;
    return this.limiter.schedule(() => this.fetchAPIData(url));
  }

  static async fetchLoadingAreas (bounds) {
    const startDate = new Date();
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);

    const endDate = new Date();
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate.setMilliseconds(999);

    const northWest = bounds.getNorthWest();
    const southEast = bounds.getSouthEast();

    let url = `${this.origin}/dui/loading-areas?`;
    url += `startTime=${startDate.toISOString()}&`;
    url += `endTime=${endDate.toISOString()}&`;
    url += `minLat=${southEast.lat}&minLon=${northWest.lng}&`;
    url += `maxLat=${northWest.lat}&maxLon=${southEast.lng}`;

    return this.limiter.schedule(() => this.fetchAPIData(url));
  }

  static async getUserInfo () {
    const url = `${this.origin}/auth/user`;
    return this.limiter.schedule(() => this.fetchAPIData(url));
  }
}
