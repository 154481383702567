import { connect, useSelector } from 'react-redux';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';

import LanguageIcon from '@mui/icons-material/Language';
import MapIcon from '@mui/icons-material/Map';
import LandscapeIcon from '@mui/icons-material/Landscape';

import {
  setSelectedTile,
  selectSelectedTile
} from '../../../features/layers/layersSlice';

import tiles from '../../../config/tiles';

const icons = {
  "osm": <MapIcon />,
  "grey": <LanguageIcon />,
  "terrain": <LandscapeIcon />
};

const ListTiles = ({ dispatch }) => {
  const selectedTile = useSelector(selectSelectedTile);

  return tiles.map((tile, index) => (
    <ListItem sx={{ p: 0 }} key={tile.name}>
      <ListItemButton onClick={() => dispatch(setSelectedTile(index))} >
        <ListItemIcon>
          {icons[tile.style]}
        </ListItemIcon>
        <ListItemText id={`list-label-${tile.name}`} primary={tile.name} />
        <Radio
          edge="end"
          checked={index === selectedTile}
          inputProps={{ 'aria-labelledby': `list-label-${tile.name}` }}
        />
      </ListItemButton >
    </ListItem>
  ));
};

export default connect()(ListTiles);
