import { Popup } from 'react-leaflet';
import { TimedText, ElapsedTimeText } from './TimedText';
import { DeliveryActions } from './DeliveryActions';

export const RoutePointPopup = ({ routePoint }) => {

  return (
    <Popup>
      <div className='route-point'>
        <h3>{`Stop #${routePoint.routeOrder} `}
          <span className="route-point-name">
            {`[${routePoint.routeName}]`}
          </span>
        </h3>
      </div>
      <TimedText routePoint={routePoint} timeType='Arrival' />
      <TimedText routePoint={routePoint} timeType='Departure' />
      <ElapsedTimeText routePoint={routePoint} />
      <br /><br />
      <DeliveryActions routePoint={routePoint} />
    </Popup>
  );
}
