import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectRelatedRP } from '../routePointsSlice';

const getTimedPostText = (routePoint, relatedRP, timeTypeText) => {
  if (routePoint.id.startsWith('executed')) {
    if (relatedRP) {
      const executedTime = new Date(routePoint[timeTypeText]);
      const plannedTime = new Date(relatedRP[timeTypeText]);
      const diff = parseInt((executedTime - plannedTime) / (60 * 1000));
      if (diff) {
        return `(${diff} ${diff === 1 ? 'minute' : 'minutes'})`;
      }
      return '';
    }
    return '(non-planned stop)';
  }
  return '(planned)';
}

export const TimedText = ({ routePoint, timeType }) => {
  const relatedRP = useSelector(state => {
    return selectRelatedRP(state, routePoint.relatedRoutePoint);
  });
  if (routePoint.id.startsWith('executed'))
    console.log("TimedText - RelatedRP", relatedRP, routePoint.routeId, routePoint.relatedRoutePoint, routePoint);  // TODO DEBUG
  const timeTypeText = useMemo(
    () => `${timeType.toLowerCase()}Time`,
    [timeType]
  );
  const time = routePoint[timeTypeText];
  const postText = useMemo(
    () => getTimedPostText(routePoint, relatedRP, timeTypeText),
    [routePoint, relatedRP, timeTypeText]
  );
  
  return (
    <div className="route-point-info">
      <span className="col route-point-time-text">
        <b>{`${timeType} time`}</b>:
      </span>
      <span className="col route-point-time">
        {new Date(time).toLocaleTimeString()}
      </span>
      <span className="col"><i>{postText}</i></span>
    </div>
  );
}

const getElapsedPostText = (routePoint, relatedRP) => {
  if (routePoint.id.startsWith('executed')) {
    if (relatedRP) {
      const plannedArrivalTime = new Date(relatedRP.arrivalTime);
      const plannedDepartureTime = new Date(relatedRP.departureTime);
      const plannedServiceTime = parseInt(
        (plannedDepartureTime - plannedArrivalTime) / (60 * 1000)
      );
      return `(Est: ${plannedServiceTime} min)`;
    }
    return '';
  }
  return '(planned)';
}

export const ElapsedTimeText = ({ routePoint }) => {
  const relatedRP = useSelector(state => {
    return selectRelatedRP(state, routePoint.relatedRoutePoint);
  });
  if (routePoint.id.startsWith('executed'))
    console.log("ElapsedTimeText - RelatedRP", relatedRP, routePoint.routeId, routePoint.relatedRoutePoint, routePoint);  // TODO DEBUG
  const text = useMemo(
    () => {
      const arrivalTime = new Date(routePoint['arrivalTime']);
      const departureTime = new Date(routePoint['departureTime']);
      const serviceTime = parseInt((departureTime - arrivalTime) / (60 * 1000));
      return `${serviceTime} ${serviceTime === 1 ? 'minute' : 'minutes'}`;
    },
    [routePoint]
  );
  const postText = useMemo(
    () => getElapsedPostText(routePoint, relatedRP),
    [routePoint, relatedRP]
  );

  return (
    <div className="route-point-info">
      <span className="col route-point-time-text">
        <i>service time</i>:
      </span>
      <span className="col route-point-time">
        {text}
      </span>
      <span className="col"><i>{postText}</i></span>
    </div>
  );
}
