import { useSelector } from 'react-redux';
import { GeoJSON, Tooltip } from 'react-leaflet';
import dayjs from 'dayjs';

import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';

import { selectRestrictionById } from './restrictionsSlice';

export const Restriction = ({ restrictionId }) => {
  const restriction = useSelector(state => {
    return selectRestrictionById(state, restrictionId)
  });
  console.log("Restriction", restrictionId, restriction);

  return (
    <GeoJSON
      data={JSON.parse(restriction.geojson)}
      style={{ color: "#ff004f" }}
      zIndex={5}
      pane="Restrictions"
    >
      <Tooltip>
        <Paper elevation={0}>
          <List>
            <ListItemText
              sx={{ my: 0, mx: 1 }}
              primary={restriction.name}
              primaryTypographyProps={{
                fontSize: 20,
                fontWeight: 'bold',
                letterSpacing: 0,
              }}
            />
            <Divider />
            <ListItem sx={{ p:0, px: 1 }}>
              <ListItemIcon sx={{ minWidth: '24px', pr: 2 }}>
                <TodayIcon />
              </ListItemIcon>
              <ListItemText
                primary={dayjs(restriction.startTime).format('L LT')}
              />
            </ListItem>
            <ListItem sx={{ p:0, px: 1 }}>
              <ListItemIcon sx={{ minWidth: '24px', pr: 2 }}>
                <EventIcon />
              </ListItemIcon>
              <ListItemText
                primary={dayjs(restriction.endTime).format('L LT')}
              />
            </ListItem>
          </List>
        </Paper>
      </Tooltip>
    </GeoJSON>
  );
};
