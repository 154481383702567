import { Route, Routes } from 'react-router-dom';

import { PersistGate } from 'redux-persist/integration/react';
import { useDispatch, Provider } from 'react-redux';
import { stores, persistors } from './app/store';

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import SenatorLogin from './screens/Login';
import SenatorLayout from './screens/SenatorLayout';
import RequireAuth from './components/RequireAuth';

import { setCity } from './features/users/usersSlice';
import { SenatorAPI } from './services/SenatorAPI';
import { SenatorBus } from './services/SenatorBus';

const Loading = () => {
  return (
    <Backdrop open={true} invisible>
      <CircularProgress />
    </Backdrop>
  )
};

const CityRouting = ({ city }) => {
  const dispatch = useDispatch();
  console.log("City Routing Initialize SenatorAPI", city.title);
  SenatorAPI.initialize(stores[city.title], city.origin);
  SenatorBus.setStore(stores[city.title]);
  dispatch(setCity({ city: city }));

  return (
    <Routes>
      <Route
        key={`${city.name}/login`}
        path={`login`}
        element={<SenatorLogin cities={[city]}/>}
      />
      <Route
        key={city.name}
        path={`*`}
        element={
          <RequireAuth city={city}>
            <SenatorLayout city={city} />
          </RequireAuth>
        }
      /> 
    </Routes>
  );
};

const City = ({ city }) => {
  console.log("City", city);

  return (
    <Provider store={stores[city.title]} >
      <PersistGate persistor={persistors[city.title]} >
        {bootstrapped => bootstrapped
          ? <CityRouting city={city} />
          : <Loading />
        }
      </PersistGate>
    </Provider>
  );
}

export default City;
