import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import * as geo from './utils/geo';

const navigationsAdapter = createEntityAdapter();
const initialState = navigationsAdapter.getInitialState({
  showPlannedNav: true,
  showExecutedNav: true
});

const navigationsSlice = createSlice({
  name: 'navigations',
  initialState,
  reducers: {
    createRouteNavigation: navigationsAdapter.addOne,
    deleteRouteNavigations: navigationsAdapter.removeMany,
    updateRouteNavigation (state, action) {
      try {
        const { routeNavId, navigation } = action.payload;
        const routeNav = state.entities[routeNavId];
        if (routeNav) {
          routeNav.navigation = navigation;
          routeNav.key++;
          routeNav.distance = geo.measureDist(navigation);
        } else {
          console.warn(`updateRouteNavigation: '${routeNavId}' does not exist`);
          navigationsAdapter.addOne({
            id: routeNavId,
            routeId: parseInt(routeNavId.split('-')[1]),
            navigation: navigation,
            distance: geo.measureDist(navigation),
            key: 0
          });
        }
      } catch(err) {
        console.error('updateRouteNavigation', err);
      }
    },
    updateRouteLastLocation (state, action) {
      const { routeNavId, position } = action.payload;
      const newLocation = [position[1], position[0]]; // It must be (lng, lat)
      const routeNav = state.entities[routeNavId];
      if (routeNav) {
        const feats = routeNav.navigation.features;
        if (feats.length === 0) {
          feats.push({
            type: "Feature",
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: [newLocation]
            }
          });
        }
        const lastFeature = feats[feats.length - 1];
        lastFeature.geometry.coordinates.push(newLocation);
        routeNav.key++;
        if (lastFeature.geometry.coordinates.length % 7 === 0) {
          routeNav.distance = geo.measureDist(routeNav.navigation);
        }
      } else {
        console.error(`Navigation '${routeNavId}' does not exist`);
      }
    },
    setShowPlannedNav (state, action) {
      state.showPlannedNav = action.payload;
    },
    setShowExecutedNav (state, action) {
      state.showExecutedNav = action.payload;
    }
  }
});

export const {
  setShowPlannedNav,
  setShowExecutedNav,
  createRouteNavigation,
  updateRouteNavigation,
  deleteRouteNavigations,
  updateRouteLastLocation
} = navigationsSlice.actions;

export default navigationsSlice.reducer;

export const {
  selectAll: selectAllNavigations,
  selectById: selectNavigationById,
  selectIds: selectNavigationIds,
  selectTotal: selectLoadedNavigations
} = navigationsAdapter.getSelectors(state => state.navigations);

export const selectShowPlannedNav = state => state.navigations.showPlannedNav;
export const selectShowExecutedNav = state => state.navigations.showExecutedNav;

export const selectPlannedNavigationById = (state, routeId) =>
  selectNavigationById(state, `planned-${routeId}`);

export const selectExecutedNavigationById = (state, routeId) =>
  selectNavigationById(state, `executed-${routeId}`);

export const selectNavigationByTypeAndId = (state, routeType, routeId) => {
  const navigationId = `${routeType}-${routeId}`;
  return selectNavigationById(state, navigationId);
}
