import { useSelector } from 'react-redux';
import { selectDepotIds } from './depotsSlice';
import { Depot } from './Depot';

export const DepotList = () => {
  const depotIds = useSelector(selectDepotIds);

  return (
    depotIds.map((depotId) => (
      <Depot key={depotId} depotId={depotId} />
    ))
  );
};
