import { useState, useMemo, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import GroupIcon from '@mui/icons-material/Group';

import {
  setSelectedShift,
  selectSelectedShift,
  selectAvailableShifts
} from '../../features/routes/stopsSlice';

const ComboShifts = ({ isGrouped, radius, dispatch }) => {
  const shifts = useSelector(selectAvailableShifts);
  const options = useMemo(() => {
    if (shifts.length > 1) {
      return ['All', ...shifts];
    }
    return shifts;
  }, [shifts]);
  const selectedShift = useSelector(selectSelectedShift);
  const [inputValue, setInputValue] = useState('');
  console.log("ComboShifts", options, selectedShift);

  useEffect(() => {
    if (options.length === 1) {
      dispatch(setSelectedShift({ shift: options[0] }));
      console.log("ComboShifts -> dispatch (1)", options[0]);
    } else if (options.length > 1 && selectedShift !== 'All') {
      if (options.indexOf(selectedShift) === -1) {
        dispatch(setSelectedShift({ shift: 'All' }));
        console.log("ComboShifts -> dispatch (2) All");
      }
    }
  }, [options, selectedShift, dispatch]);

  return (
    <Autocomplete
      id="combo-box-shifts"
      disableClearable={true}
      options={options}
      fullWidth
      sx={{
        height: 48,
        "& .MuiAutocomplete-popupIndicator": { transform: "none" }
      }}
      popupIcon={
        <GroupIcon />
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={isGrouped ? "" : "Shift"}
          variant="outlined"
          error={shifts.length === 0}
          sx={{
            "& .MuiFilledInput-root": {
              background: "white",
              height: '48px',
              borderRadius: isGrouped ? 0 : radius,
              alignItems: isGrouped ? "center" : "flex-end"
            },
            "& .MuiAutocomplete-inputRoot": {
              borderTopRightRadius: radius,
              borderBottomRightRadius: radius,
              borderTopLeftRadius: isGrouped ? 0 : radius,
              borderBottomLeftRadius: isGrouped ? 0 : radius,
              padding: '5px',
            },
            "& .MuiInputLabel-root": {
              transform: 'translate(18px, 4px) scale(0.75)'
            },
            "& .MuiOutlinedInput-notchedOutline>legend>span": {
              display: isGrouped ? "inline-block" : "none"
            },
            "& .MuiOutlinedInput-root": {
              paddingTop: "5px !important",
              paddingBottom: "5px !important"
            },
            "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
              paddingLeft: isGrouped ? "5px" : "13px",
              paddingBottom: isGrouped ? "7.5px" : "0px !important",
              paddingTop: isGrouped ? "7.5px" : "15px !important"
            }
          }}
        />
      )}
      value={selectedShift}
      onChange={(event, newValue) => {
        console.log("ComboShifts onChange");
        dispatch(setSelectedShift({ shift: newValue }));
        console.log("ComboShifts -> dispatch (3)", newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        console.log("ComboShifts onInputChange");
        setInputValue(newInputValue);
      }}
    />
  );
}

export default connect()(ComboShifts);
