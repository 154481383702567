import L from 'leaflet';
import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux'
import { Marker } from 'react-leaflet';
import { RoutePointPopup } from './RoutePointPopup';
import { selectRoutePointById, selectRelatedRP } from './routePointsSlice';

const plannedStopStyle = {
  background: '#fff',
  color: '#fff',
  'border-color': '#fff',
};
const executedStopStyle = {
  background: '#fff',
  color: '#fff',
  'border-color': '#fff',
  'z-index': 500
};
const errorStopStyle = {
  background: '#fff',
  color: '#fff',
  /*color: '#000',*/
  'border-color': '#fff',
  'box-shadow': '0 0 0 4px red',
  outline: 'dashed 4px black',
  'border-width': '1px',
  'z-index': 600,
  'line-height': '16px'
}; 
const delayedStopStyle = {
  background: '#fff', // to be changed to color
  color: '#fff',
  'border-color': '#fff',
  'box-shadow': '0 0 0 4px #fff',
  'outline-width': '3px',
  'outline-style': 'dashed',
  'outline-color': 'black', // to be changed to color
  'border-width': '1px',
  'z-index': 600,
  'line-height': '16px'
};
/*
const errorStopStyle = {
  background: 'red',
  color: 'black',
  'border-color': 'black',
  'z-index': '600 !important'
};
*/


const getMarkerClass = (actions) => {
  let markerClass = 'voidMarker';
  if (actions.length > 0) {
    if (actions.map(x => x.actionClass).every(x => x === actions[0].actionClass)) {
      switch(actions[0].actionClass) {
        case 'load': markerClass = 'loadMarker'; break;
        case 'unload': markerClass = 'unloadMarker'; break;
        case 'delivery': markerClass = 'deliveryMarker'; break;
        default: markerClass = 'pickupMarker';
      }
    } else {
      markerClass = 'mixMarker';
    }
  }
  return markerClass;
}

const anyFailedAction = (actions) => {
  const failedActions = actions.filter(x => {
    return x.actionStatus.indexOf('failed') !== -1
  })
  return failedActions.length !== 0;
}

const generateStyle = (styleObject) => {
  return Object.entries(styleObject).map(([k, v]) => `${k}:${v}`).join(';');
}

const getMarkerStyle = (routePoint, failedActions, isDelayed, color) => {
  let markerStyle = '';
  if (routePoint.routeType === 4) {
    if (failedActions) {
      markerStyle = generateStyle({...errorStopStyle, background: color });
      // markerStyle = generateStyle({...errorStopStyle, background: color+'99', color: 'black' });
    } else if (isDelayed) {
      markerStyle = generateStyle({
        ...delayedStopStyle,
        background: color,
        'outline-color': color
      });
    } else {
      markerStyle = generateStyle({...executedStopStyle, background: color});
    }
  } else {
    markerStyle = generateStyle(
      {...plannedStopStyle, color: color+'99', 'border-color': color+'4d'}
    );
  }
  return markerStyle;
}

const getClassName = (routePoint, failedActions) => {
  let className = 'marker-icon';
  if (routePoint.id.startsWith('executed')) {
    className += ' executed-marker';
    if (failedActions) {
      className += '-error';
    }
  }
  return className;
}

const checkDelayed = (routePoint, relatedRoutePoint) => {
  if (routePoint.id.startsWith('executed')) {
    if (relatedRoutePoint) {
      const executedTime = new Date(routePoint['arrivalTime']);
      const plannedTime = new Date(relatedRoutePoint['arrivalTime']);
      return (executedTime - plannedTime) / (60 * 1000) > 20;
    }
    return true;
  }
  return false;    
}

export const RoutePoint = ({ routePointId, pointColor }) => {
  const [icon, setIcon] = useState(null);
  const routePoint = useSelector(
    state => selectRoutePointById(state, routePointId)
  );
  const relatedRP = useSelector(state => {
    return selectRelatedRP(state, routePoint.relatedRoutePoint);
  });
  const actions = routePoint.actions;
  const isDelayed = useMemo(
    () => checkDelayed(routePoint, relatedRP),
    [routePoint, relatedRP]
  )
  const failedActions = useMemo(() => anyFailedAction(actions), [actions]);
  const markerClass = useMemo(() => getMarkerClass(actions), [actions]);
  const markerStyle = useMemo(
    () => getMarkerStyle(routePoint, failedActions, isDelayed, pointColor),
    [routePoint, failedActions, isDelayed, pointColor]
  );
  const className = useMemo(
    () => getClassName(routePoint, failedActions),
    [routePoint, failedActions]
  );
  const routeOrder = routePoint.routeOrder;
  const iconSize = useMemo(() => {
    return failedActions || isDelayed ? [18, 18] : [23, 23];
  }, [failedActions, isDelayed]);


  useEffect(() => {
    const generateIcon = async () => {
      setIcon(L.divIcon({
        className: className,
        iconSize: iconSize,
        html: `<div
          style="${markerStyle}"
          class="senator-marker ${markerClass}"
        >${routeOrder}</div>`
      }));
    };

    generateIcon();
  }, [className, iconSize, markerStyle, markerClass, routeOrder]);

  return icon && (
    <Marker position={routePoint.position} icon={icon} >
      <RoutePointPopup routePoint={routePoint} />
    </Marker>
  );
}
