import { Client } from 'paho-mqtt';
import {
  newRoute,
  updateRoute,
  setErrorMessage
} from '../features/routes/routesSlice';
import { vehicleLocation } from '../features/transports/vehiclesSlice';
import {
  trackerLocation,
  shipmentStatus
} from '../features/transports/trackersSlice';

const brokerUrl = 'wss://sagresearch.de:8080/ws';

export class SenatorBus {
  static store = null;
  static randomId = parseInt(Math.random()*10000000);
  static client = new Client(brokerUrl, `DeuDash${this.randomId}`);

  static setStore(store) {
    this.store = store;
    console.log("SenatorBus", this.store);
  }

  static connect (mqttUser, mqttPass, useSSL, topic) {
    if (!this.client || !this.client.isConnected()) {
      try {
        this.client.onConnectionLost = onConnectionLost;
        this.client.onMessageArrived = onMessageArrived;
        const connectOptions = {
          useSSL: useSSL,
          userName: mqttUser,
          password: mqttPass,
          keepAliveInterval: 30,
          reconnect: true,

          onSuccess: () => {
            console.log("MQTT Client connected", this.client.isConnected());
            this.client.subscribe(topic);
            console.log("MQTT subscribed to", topic);
          },
          onFailure: (error) => {
            console.error('MQTT conexion error:', error.message);
            this.store.dispatch(setErrorMessage({
              title: 'MQTT conexion error',
              message: error.message
            }));
          },
        } 
        this.client.connect(connectOptions);
      } catch(err) {
        if (err.message && err.message.startsWith("AMQJS0011E")) {
          console.log("MQTT error?", err.message, this.client.isConnected());
        }
      }
    }
  }

  static disconnect () {
    console.log("MQTT disconnect", this.client.isConnected());
    if (this.client && this.client.isConnected()) {
      this.client.disconnect();
    }
  }

  static isConnected () {
    return this.client && this.client.isConnected();
  }
}

function onConnectionLost (responseObject) {
  console.log("MQTT Client disconnected", responseObject);
  if (responseObject.errorCode !== 0) {
    console.log('MQTT conexion lost:', responseObject.errorMessage);
    SenatorBus.isConnected = false;
  }
}

async function onMessageArrived (message) {
  const msg = JSON.parse(message.payloadString);
  try {
    console.log("New message: ", msg);
    if (msg.msgtype === 'vehicle_location') {
      console.log("vehicle_location");
      SenatorBus.store.dispatch(vehicleLocation({
          vehicleId: msg.vehicle_id,
          position: [msg.latitude, msg.longitude],
          hour: new Date(msg.timestamp).getHours()
      }));
    } else if (msg.msgtype === 'tracker_location') {
      console.log("tracker_location");
      SenatorBus.store.dispatch(trackerLocation({
        trackerId: msg.tracker_id,
        position: [msg.latitude, msg.longitude]
      }));
    } else if (msg.msgtype === 'shipment_status') {
      if (msg.tracker_id === "SENATOR") {
        return;
      }
      console.log("shipment_status");
      SenatorBus.store.dispatch(shipmentStatus({
        trackerId: msg.tracker_id,
        shipmentCode: msg.package_id,
        timestamp: msg.timestamp,
        position: [msg.latitude, msg.longitude],
        actionStatus: msg.delivery_action,
        substatus: msg.status
      }));
    } else if (msg.msgtype === 'new_route') {
      console.log("new_route");
      SenatorBus.store.dispatch(newRoute({
        routeId: msg.route_id,
        journey: msg.journey
      }));
    } else if (msg.msgtype === 'route_updated') {
      console.log("route_updated");
      SenatorBus.store.dispatch(updateRoute({
        routeId: msg.route_id,
      }));
    }
  } catch(err) {
    console.error("MQTT Error processing bus msg:", err);
    SenatorBus.store.dispatch(setErrorMessage({
      title: 'Error processing BUS msg',
      message: err.errorMessage
    }));
  }
}
