import Stack from '@mui/material/Stack';

import ShrinkablePaper from './ShrinkablePaper';
import ComboJourneys from './ComboJourneys';
import ComboDepots from './ComboDepots';
import ComboShifts from './ComboShifts';
import ComboRoutes from './ComboRoutes';

const AllCombos = () => {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      sx={{minWidth: 300, mr: 2}}
    >
      <ShrinkablePaper>
        <ComboJourneys isGrouped={false} radius='23px'/>
      </ShrinkablePaper>
      <ShrinkablePaper>
        <ComboDepots isGrouped={false} radius='23px'/>
      </ShrinkablePaper>
      <ShrinkablePaper>
        <ComboShifts isGrouped={false} radius='23px'/>
      </ShrinkablePaper>
      <ShrinkablePaper>
        <ComboRoutes isGrouped={false} radius='23px'/>
      </ShrinkablePaper>
    </Stack>
  );
}

export default AllCombos;;
