import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectRelatedActions } from '../routePointsSlice';

const getNumActionsText = (actions) => {
  switch (actions.length) {
    case 0: return 'No actions';
    case 1: return '1 action';
    default: return `${actions.length} actions`;
  }
}

export const DeliveryActions = ({ routePoint }) => {
  const actions = useSelector(state => {
    return selectRelatedActions(state, routePoint)
  });
  const numActionsText = useMemo(() => getNumActionsText(actions), [actions]);

  return (
    <div className="route-point-info">
      <div className="col">
        {(actions.length > 0)
          ? <><b>{numActionsText}</b>:</>
          : <i>{numActionsText}</i>
        }
      </div>
        <div className="route-point-container" >
         {actions.map((action) => (
           <DeliveryAction
             key={action.shipment}
             action={action}
           />
         ))}
        </div>
    </div>
  );
}

const getDeliveryActionIcon = (action) => {
  let icon = '';
  switch (action.actionClass) {
    case 'load': icon = '/load.svg'; break;
    case 'unload': icon = '/unload.svg'; break;
    case 'pick-up': icon = '/pickup.svg'; break;
    case 'delivery': icon = '/delivery.svg'; break;
    default: icon = 'delivery.svg';
  };
  return icon;
};

const getExecutedStatusText = (action) => {
  return action.actionStatus.indexOf('failed') !== -1
    ? '❌'
    : '✅';
}

export const DeliveryAction = ({ action }) => {
  const icon = useMemo(() => getDeliveryActionIcon(action), [action]);
  const executedStatusText = useMemo(
    () => getExecutedStatusText(action), [action]
  );

  return (
    <div className='delivery-action'>
      <span className="col">
        <img src={icon} className='delivery-action-icon' alt={action.actionClass} />
      </span>
      <span className="col action-code"><b>{action.code}</b></span>
      <span className='col action-status'>
        {action.actionType === 4
          ? <>{action.actionStatus}</> : <i>{action.actionStatus}</i>}
      </span>
      {action.actionType === 4 && (<>{executedStatusText}</>)}
    </div>
  );
}
