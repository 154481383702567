import { useSelector, connect } from 'react-redux';

import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
// import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import AlertsIconButton from './SenatorIconButtons/AlertsIconButton';
import SenatorIconButtons from './SenatorIconButtons';

import {
  selectUser,
  selectOrigin,
  clearStateAndExit
} from '../features/users/usersSlice';

import { SenatorAPI } from '../services/SenatorAPI';

const LateralMainBar = ({ isMenuVisible, toggleMenu, dispatch }) => {
  const user = useSelector(selectUser);
  const origin = useSelector(selectOrigin);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const isSmallHeight = useMediaQuery('(max-height:670px)');
  const isXSHeight = useMediaQuery('(max-height:595px)');
  const smallAppBar = isMediumScreen || isSmallHeight;
  const orgLogo = user ? `${origin}${user.organization.logo}` : '';
  const iconButtonSize = smallAppBar ? "medium" : "large";
  const showAppBar = !isSmallScreen || isMenuVisible;
  console.log("LateralMainBar", isSmallScreen, isMediumScreen, isSmallHeight, isXSHeight)

  return (
    <Box flexGrow={0} >
    <Slide direction="right" in={showAppBar} >
      <AppBar
        elevation={1}
        position={isSmallScreen ? "fixed" : "sticky"}
        color="background"
        sx={{
          padding: smallAppBar ? 1 : 2,
          height: "100%",
          width: "fit-content",
          left: 0,
          backgroundColor: "whitesmoke",
          borderRight: "1px solid #d5d5d5"
        }}
      >
        <Stack
         spacing={{ height: "100vh" }}
         divider={<Divider flexItem />}
         useFlexGap
         justifyContent="space-between"
        >
        <Stack
         spacing={ isXSHeight ? "20%" : "60%" }
         alignItems="center"
         justifyContent="space-around"
        >
        <Avatar
         src="/senator.svg"
         alt="Logo"
         onClick={isSmallScreen ? toggleMenu : () => {}}
         sx={{
           width: smallAppBar ? 40 : 52,
           height: smallAppBar ? 40 : 52,
           transition: "transform 0.1s",
           '&:hover': isSmallScreen ? { transform: "scale(0.9)" } : {}
         }}
         imgProps={{ sx: { objectFit: 'fill'} }}
         variant="square"
        />
        <Avatar
          src={orgLogo}
          imgProps={{ sx: { objectFit: 'fill'} }}
          sx={{
            background: "white",
            border: "1px solid #d5d5d5",
            width: smallAppBar ? 40 : 52,
            height: smallAppBar ? 40 :  52,
            padding: 1
          }}
        />
        <AlertsIconButton size={iconButtonSize} place="right" />
        </Stack>
        <SenatorIconButtons isXSHeight={isXSHeight} size={iconButtonSize} />
        <Stack
         spacing={ isXSHeight ? "20%" : "60%" }
         alignItems="center"
         justifyContent="space-around"
        >
          <Tooltip
            title="Delete saved state and exit"
            placement="right"
            arrow
            disableHoverListener={false}
          >
            <IconButton
              aria-label="Clear state & exit"
              size={iconButtonSize}
              onClick={() => {
                dispatch(clearStateAndExit());
              }}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Log out" placement="right" arrow>
            <IconButton
              aria-label="Log out"
              size={iconButtonSize}
              onClick={() => SenatorAPI.logout()}
            >
              <PowerSettingsNewOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        </Stack>
      </AppBar>
    </Slide>
    </Box>
  );
}

export default connect()(LateralMainBar);
