import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit'

const transportsAdapter = createEntityAdapter();

const initialState = transportsAdapter.getInitialState({
  showTransports: true
});

export const updateTransportsRoutes = createAsyncThunk(
  'trasports/updateTrasportsRoutes',
  async (_, thunkAPI) => {
    try {
      console.log('updateTransportsRoutes (1)');
      const state = thunkAPI.getState();
      state.transports.ids.forEach(id => {
        const transport = state.transports.entities[id];
        if (transport.routes.length > 1) {
          transport.routes.sort();
          for (let i = transport.routes.length-1; i >= 0; i--) {
            const routeId = transport.routes[i][1];
            const route = state.routes.entities[routeId];
            if (route.executed.stops.length > 0) {
              transport.currentRoute = route.id;              
            }
          }
        }
        const routeNavId = `executed-${transport.currentRoute}`;
        const currentRouteNav = state.navigations.entities[routeNavId];
        const feats = currentRouteNav.navigation.features;
        if (feats.length > 0) {
          const lastFeatCoords = feats[feats.length - 1].geometry.coordinates;
          const position = lastFeatCoords[lastFeatCoords.length - 1];
          thunkAPI.dispatch(updateTransportLocation({
            transportId: transport.id,
            position: [position[1], position[0]]
          }));
        }
      });
      console.log('updateTransportsRoutes (2) end');
    } catch(err) {
      console.error("updateTransportsRoutes", err);
    }
  }
);

const transportsSlice = createSlice({
  name: 'transports',
  initialState,
  reducers: {
    createTransport: transportsAdapter.addOne,
    updateRouteLocation (state, action) {
      const { transportId, routeId, position } = action.payload;
      const transport = state.entities[transportId];
      if (transport.currentRoute === routeId) {
        transport.position = position;          
      }
    },
    updateTransportLocation (state, action) {
      try {
        const { transportId, position } = action.payload;
        console.log("UpdateTransportLocation (1)", transportId, position);
        const transport = state.entities[transportId];
        console.log("UpdateTransportLocation (2)", transport);
        transport.position = position;
      } catch (err) {
        console.error("UpdateTransportLocation ", err);
      }
    },
    setShowTransports (state, action) {
      state.showTransports = action.payload;
    }
  }
});

export const {
  createTransport,
  setShowTransports,
  updateRouteLocation,
  updateTransportLocation
} = transportsSlice.actions;

export default transportsSlice.reducer;

export const {
  selectAll: selectAllTransports,
  selectById: selectTransportById,
  selectIds: selectTransportIds,
  selectTotal: selectTotalTransports
} = transportsAdapter.getSelectors(state => state.transports);

export const selectShowTransports = state => state.transports.showTransports;

export const selectRouteTransport = (state, routeId) => {
  const transportId = state.routes.entities[routeId].transportId;
  const transport = state.transports.entities[transportId];
  if (transport.currentRoute === routeId) {
    return transport;    
  }
  return null;
}

export const selectTransportCurrentLoad = createSelector(
  state => state.stops.entities,
  state => state.routePoints.entities,
  (state, transportId) => state.transports.entities[transportId],
  (stops, routePoints, transport) => {
    if (transport) {
      const routeStops = stops[`executed-${transport.currentRoute}`];
      const currentLoad = routeStops && routeStops.stops.length > 0
        ? transport.model.maxVolume -
          routePoints[
            routeStops.stops[routeStops.stops.length - 1]
          ].departureCapacity
        : 0;
      return currentLoad;
    }
    return 0;
  }
);
