import { useSelector } from 'react-redux'
import { Backdrop, Box, LinearProgress } from '@mui/material';

import {
  selectLoadStatus,
  selectProgressPosition
} from '../features/routes/routesSlice';
import { selectToken } from '../features/users/usersSlice';

const LoadingOverlay = () => {
  const loadStatus = useSelector(selectLoadStatus);
  const token = useSelector(selectToken);
  const progressPosition = useSelector(selectProgressPosition);
  const shouldShowLoading = loadStatus === 'loading' || token == null;
  console.log("LoadingOverlay", progressPosition, loadStatus, token);

  return (
    <Backdrop
      display="flex"
      open={shouldShowLoading}
      sx={{
        flexDirection: "column",
        backgroundColor: "transparent",
        backdropFilter: "blur(5px)",
        zIndex: 99999,
        filter: `drop-shadow(-1px -1px 5px #fafaf8)
                 drop-shadow(1px -1px 5px #fafaf8) 
                 drop-shadow(1px 1px 5px #fafaf8)
                 drop-shadow(-1px 1px 5px #fafaf8)`
      }}
    >
      <img src='/senator-logo.png' className='loading' alt="loading"/>
      <Box
        width="25%"
        mt={2}
        visibility={progressPosition === 0 ? "hidden" : "visible"}
      >
        <LinearProgress
          variant="determinate"
          value={progressPosition}
        />
      </Box>
    </Backdrop>
  );
}

export default LoadingOverlay;
