import { ComputeFilter } from './colorize';

const baseColors = [
  [255, 0, 79],
  [0, 168, 116],
  [49, 0, 255],
  [255, 176, 0],
  [99, 168, 0],
  [176, 0, 255],
  [0, 161, 236],
  [255, 102, 0],
  [0, 0, 139],
  [139, 0, 0],
  [234, 193, 2],
  [234, 2, 159],
  [0, 204, 0],
  [255, 55, 0],
  [206, 92, 30],
  [0, 200, 255],
  [150, 0, 150],
  [0, 153, 0],
  [255, 205, 0],
  [255, 149, 0],
  [205, 133, 63],
  [0, 139, 139],
  [128, 128, 0],
  [255, 84, 0],
  [255, 0, 0],
  [0, 100, 0],
  [255, 0, 164],
  [255, 0, 254],
  [185, 0, 255],
  [135, 0, 255],
  [0, 0, 255],
  [0, 84, 255],
  [0, 155, 255],
  [200, 0, 0],
  [244, 207, 15],
  [75, 0, 130],
  [0, 139, 0],
  [255, 116, 255],
  [179, 110, 190],
  [76, 145, 65],
  [245, 64, 33],
  [0, 191, 236],
  [0, 150, 150],
  [255, 140, 0],
  [0, 150, 0],
  [11, 48, 240],
  [229, 29, 46],
  [184, 134, 11],
  [128, 0, 128],
  [160, 82, 45],
  [139, 0, 139],
  [139, 69, 19],
  [165, 42, 42],
  [150, 150, 0],
  [0, 150, 100],
  [102, 0, 0],
  [153, 0, 0],
  [204, 0, 0],
  [204, 51, 0],
  [255, 51, 0],
  [204, 102, 0],
  [204, 153, 0],
  [255, 153, 0],
  [204, 204, 0],
  [255, 204, 0],
  [0, 102, 0],
  [2, 234, 77],
  [72, 255, 0],
  [0, 255, 0],
  [0, 204, 51],
  [0, 255, 51],
  [0, 204, 102],
  [0, 255, 102],
  [0, 204, 153],
  [0, 255, 153],
  [0, 204, 204],
  [0, 255, 204],
  [0, 102, 102],
  [0, 153, 153],
  [0, 255, 255],
  [51, 0, 102],
  [51, 0, 153],
  [102, 0, 204],
  [102, 0, 255]
]

export const colors = baseColors.map(color => {
  const hexColor = typeof color === 'string'
    ? color
    : `#${color[0].toString(16).padStart(2, '0')}` +
      `${color[1].toString(16).padStart(2, '0')}` +
      `${color[2].toString(16).padStart(2, '0')}`;
  return {
    color: hexColor,
    filter: new ComputeFilter(hexColor)
  };
});
