import { useState } from 'react';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Slide from '@mui/material/Slide';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

import OPMIconButton from './OPMIconButton';
import MFMIconButton from './MFMIconButton';
import SRMIconButton from './SRMIconButton';
import DUIIconButton from './DUIIconButton';


const SenatorCondensedMenu = ({ size }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => setAnchorEl(e.currentTarget);

  return (
    <>
      <IconButton arial-label="Main menu" size={size} onClick={handleClick} >
        <MenuOutlinedIcon />
      </IconButton>
      <Popover
        elevation={0}
        PaperProps={{ style: { background: "transparent"}}}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
      <Slide direction="right" in={anchorEl != null} >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{ ml: 4, height: "50px" }}
        >
          <Paper sx={{ borderRadius: "50%"}} elevation={2} >
            <OPMIconButton size={size} place={"top"} />
          </Paper>
          <Paper sx={{ borderRadius: "50%"}} elevation={2} >
            <MFMIconButton size={size} place={"top"} />
          </Paper>
          <Paper sx={{ borderRadius: "50%"}} elevation={2} >
            <SRMIconButton size={size} place={"top"} />
          </Paper>
          <Paper sx={{ borderRadius: "50%"}} elevation={2} >
            <DUIIconButton size={size} place={"top"} />
          </Paper>
        </Stack>
      </Slide>
      </Popover>
    </>
  );
}

const SenatorIconButtons = ({ isXSHeight, size }) => {

  return (
    <Stack
      spacing={ isXSHeight ? "20%" : "60%" }
      alignItems="center"
      justifyContent="space-around"
    >
      {isXSHeight
        ? <SenatorCondensedMenu size={size} />
        : (<>
          <OPMIconButton size={size} place={"right"} />
          <MFMIconButton size={size} place={"right"} />
          <SRMIconButton size={size} place={"right"} />
          <DUIIconButton size={size} place={"right"} />
        </>)
      }
    </Stack>
  );
}

export default SenatorIconButtons;
