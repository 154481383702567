import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

const AlertsIconButton = ({ size, place }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const selected = location.pathname.endsWith('/alerts');
  const showTooltip = !selected && open;

  useEffect(() => {
    if (selected) {
      setOpen(false);
    }
  }, [selected]);

  return (
    <Tooltip
      open={showTooltip}
      title="Alerts"
      placement={place}
      arrow
      disableHoverListener={selected}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <span>
        <IconButton
          aria-label="Alerts"
          size={size}
          disabled={selected}
          onClick={() => navigate("alerts")}
        >
          <NotificationsOutlinedIcon color={selected ? "primary" : ""} />
        </IconButton>
      </span>
    </Tooltip>
  );
}

export default AlertsIconButton;
