import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { selectToken, selectCity } from '../features/users/usersSlice';

const RequireAuth = ({ children, city }) => {
  const currentCity = useSelector(selectCity);
  const token = useSelector(selectToken);
  const isLogged = (
    token != null &&
    token.exp * 1000 > new Date() &&
    currentCity && currentCity.name === city.name
  );
  console.log("Logged?", isLogged, token, currentCity, city);
  const destination = city.name
    ? `/${city.name}/login`
    : "/login";

  if (!isLogged) {
    console.log("Logged: NOT -> redirect", city);
    return <Navigate to={destination} replace={true} />
  }

  return children;
}

export default RequireAuth;
