import Paper from '@mui/material/Paper';

const ShrinkablePaper = (props) => {
  const { children, ...otherProps } = props;

  return (
    <Paper
      elevation={2}
      sx={{
        borderRadius: "23px",
        minWidth: 100,
        width: 300,
        ...otherProps,
      }}
    >
      {children}
    </Paper>
  );
};

export default ShrinkablePaper;
