import { Popup } from 'react-leaflet';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell

} from '@mui/material'

export const DepotPopup = ({ depot }) => {
  return (
    <Popup>
      <Card sx={{ maxWidth: 301, border: "none", padding: 0 }} variant="outlined" >
        <CardHeader title={depot.name} titleTypographyProps={{variant:'h6' }} />
        <CardContent >
          <Table sx={{ maxWidth: 340}} size="small" aria-label="Depot's features">
            <TableBody>
              <TableRow
                key='Address'
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 }
                }}
              >
                <TableCell
                  component="th"
                  align="left"
                  padding="none"
                  sx={{ fontSize: 12, verticalAlign: 'top' }}
                >
                  <strong>{'Address:'}</strong>
                </TableCell>
                <TableCell
                  align="right"
                  padding="none"
                  sx={{ fontSize: 12, verticalAlign: 'top' }}
                >
                  {depot.address}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Popup>
  );
}
